@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?nwct6l');
  src:  url('fonts/icomoon.eot?nwct6l#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nwct6l') format('truetype'),
    url('fonts/icomoon.woff?nwct6l') format('woff'),
    url('fonts/icomoon.svg?nwct6l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e900";
  color: #c2c5cd;
}
.icon-close:before {
  content: "\e901";
  color: #7b8191;
}
.icon-delete:before {
  content: "\e902";
  color: #b5b8c0;
}
.icon-delete-red:before {
  content: "\e903";
  color: #b73c3c;
}
.icon-dots:before {
  content: "\e904";
  color: #fff;
}
.icon-double-shift-down:before {
  content: "\e905";
  color: #c2c5cd;
}
.icon-green-circle .path1:before {
  content: "\e906";
  color: rgb(26, 112, 81);
}
.icon-green-circle .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(26, 112, 81);
  opacity: 0.5;
}
.icon-info:before {
  content: "\e908";
  color: #7b8191;
}
.icon-menu:before {
  content: "\e909";
  color: #c2c5cd;
}
.icon-polygon-down:before {
  content: "\e90a";
  color: #505767;
}
.icon-polygon-left:before {
  content: "\e90b";
  color: #fff;
}
.icon-polygon-right:before {
  content: "\e90c";
  color: #fff;
}
.icon-result:before {
  content: "\e90d";
  color: #fff;
}
.icon-search:before {
  content: "\e90e";
  color: #7b8191;
}
.icon-shift-up:before {
  content: "\e90f";
  color: #c2c5cd;
}
.icon-sign-green-right:before {
  content: "\e910";
  color: #1a7051;
}
.icon-star:before {
  content: "\e911";
  color: #7b8191;
}
.icon-time:before {
  content: "\e912";
  color: #c2c5cd;
}
