@import "colors";

.l-column-wrapper{
    width: 15.6771%;
    height: 100%;
    overflow:hidden;
    float:left;
    .finished-tickets {
        height: 80%;
        overflow-y: scroll;
        .ticket-text {
            letter-spacing: 0.33px;
            background-color: #50626c;
            color: #e5e5e5;
            text-align: center;
            overflow: visible;
            font-size: 14px;
            padding: 2px;

        }
    }
}
.l-column-mini{
    width: 2%;
}

.l-top-box {
    width: 100%;
}

.lottery-tab-wrapper{
    width:100%;
    height:auto;
.lottery_tabs{
    width:40%;
    height:38px;
    display:inline-block;
    background: $EbonyClay;
    border-bottom: 1px solid $JazzberryJam;
    color: $Mischka;
    text-transform:uppercase;
    padding:0 5%;
   
    white-space:nowrap;
    > div{
        display:inline-block;
        line-height: 37px;
        &:second-child{
            color:$StormGrey;
        }
    }
    .text{
        letter-spacing: 0.36px;
        //font-size: 12px;
        font-size:0.7vw;
        width:60%;
    }
    .count{
        letter-spacing: 0.3px;
        //font-size: 10px;
        font-size:0.55vw;
        text-align: right;
        width:40%;
        >span{
            border-radius: 50px;
            padding: 2px 5px;
            background:$BlackShade;
        }
    }
    &.active{
        color: $white;
        background: $JazzberryJam;
    }
    }
}
.filter-view {
    box-sizing: border-box;
    width: 100%;
    height: 72px;
    background: $MidnightExpress;
    padding: 3px 8px 3px 5px;
    .r-tab {
        float: right;
        border: none;
        box-sizing: border-box;
        width: 30%;
        height: 24px;
        cursor: pointer;
        .r-text {
            //text-transform: uppercase;
            letter-spacing: 0.33px;
            border-radius: 2px;
            background-color: #1A7051;
            padding: 2%;
            font-size: 12px;
            text-align: center;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

        }
    }

}
.filter-row {
    float: left;
    box-sizing: border-box;
    width: 70%;
    height: 24px;
    position: relative;
    display: flex;
    >ul {
        padding: 0;
        margin: 0;
        list-style: none;
        box-sizing: border-box;
        >li{
            box-sizing: border-box;
            padding: 0 1px;
            cursor: pointer;
        }
    }
}
.time-filter {
    box-sizing: border-box;
    height: 24px;
    position: relative;
    z-index: 271;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 0 30px;
    color: $DarkGray;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    margin:0;
    /*>p{
        box-sizing: border-box;
        width: 100%;
        position: relative;
        padding: 0 30px;
        color: $DarkGray;
        font-size: 12px;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: uppercase;
        margin:0;*/
        &:before {
            left: 0;
            content: "\e912";
            box-sizing: border-box;
            width: 28px;
            height: 24px;
            position: absolute;
            top: 0;
            display: block;
            color: $StormGrey;
            font: 19px/24px icomoon;
            text-align: center;
        }
        .arrow-rotate-filter{
            right: 0;
            box-sizing: border-box;
            width: 28px;
            height: 24px;
            position: absolute;
            top: 0;
            display: block;
            color: $BrightGrey;
            text-align: center;
            font-style: normal;
            &:before{
                box-sizing: border-box;
                content: "\e90a";
                font: 30px/24px icomoon;
                display: block;
            }
        }
   // }
    &.active{
        background: #363945;
    }
    &.active .arrow-rotate-filter:before {
        transform: rotateX(180deg);
    }
    select {
        background-color: $MidnightExpress;
        color: $StormGrey;
        border: none;
        box-shadow: 0 0 0 1.2px $BlueZodiac inset;

    }
}

.second-row-filter{
    padding: 0 32px 0 0;
    box-sizing: border-box;
    float: left;
    width: 100%;
    position: relative;
    margin: 8px 0 0;
}
.search-box{
    box-sizing: border-box;
    float: left;
    width: 100%;
    height: 30px;
    position: relative;
    z-index: 988;
    &:before {
        right: 12px;
        box-sizing: border-box;
        position: absolute;
        font: 24px/30px icomoon;
        content: "\e90e";
        color: $StormGrey;
        display: block;
        top: 0;
        z-index: 5;
    }
    >form {
        box-sizing: border-box;
        float: left;
        width: 100%;
        height: 30px;
    }
    .search-results-container {
            .games-list-mini-view {
                padding: 0;
                .competition-title {
                    margin: 1px 0 0;
                }
            }
        }
    .block-search {
        box-sizing: border-box;
        float: left;
        width: 100%;
        margin: 0;
        background: none;
        border-radius: inherit;
        z-index: 77;
        position: static;
        padding: 0;
        min-width: 35px;
        height: 30px;
        text-align: right;
        max-width: 250px;

        input[type=text] {
            padding: 0 30px 0 10px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 0;
            color: $StormGrey;
            background: none;
            width: 100%;
            height: 100%;
            border:none;
            box-shadow: 0 0 0 1.2px $VulcanShade inset;
            font-size: 12px;
            line-height: 30px;
            color: $StormGrey;
            z-index: 22;
            border-radius: inherit;
            transition: box-shadow 240ms;
            margin: 0;
            &:focus {
                box-sizing: border-box;
                box-shadow: 0 0 0 1.2px $BrightGrey inset;
                outline:none;
            }
            &::placeholder {
                color:$StormGrey;
              }
        }

    }
}

.closed-left-nav {
    right: 3px;
    box-sizing: border-box;
    width: 20px;
    height: 16px;
    position: absolute;
    top: 7px;
    color: $LinkWater;
    z-index: 111;
    transform: translate3d(0, 0, 0);
    cursor:pointer;
    &:before {
        box-sizing: border-box;
        content: "\e909";
        color: $LinkWater;
        font: 19px/16px icomoon;
    }
}

.l-full-menu-wrapper-box {
    box-sizing: border-box;
    text-align: left;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    margin-top:5px;
    //padding: 0 1px 0 0;
}

.l-full-menu-wrapper {
    width: 100%;
    float: left;
}
.left-nav-view, .favorites{
    box-sizing: border-box;
    float: left;
    width: 100%;
    >ul{
        margin:0;
        padding:0;
        box-sizing: border-box;
        float: left;
        width: 100%;
        list-style-type: none;
        >li{
            list-style-type: none;
            box-sizing: border-box;
            width: 100%;
            margin: 0 0 1px;
            float: left;
            .float-width{
                width: 100%;
                float: left;
                display: block;
                padding-bottom: 2px;
            }
        }
    }
}

/*Bootstrap css start*/
*{
    box-sizing: unset;
}

.Collapsible{
    .Collapsible__trigger.is-open{
      .sports-item{
        background: #2e323e;
        >p{
          color: $LinkWater;
        }
      }
      .region-item{
        background: $MidnightExpress;
        color: $LinkWater;
      }
      .arrow-view{
        &:before{
          transform: rotateX(0deg);
          color:$LinkWater;
          display:block;
        }
      }
      .show-more{
        background: $VulcanShade;
        &:before{
            transform: rotateX(180deg);
        }
      }
    }
    .Collapsible__contentOuter{
      transition: height 100ms linear 0s !important;
      float:left;
      width:100%;
    }
    .Collapsible__contentInner{
        .competition-title, .fav-competition{
            &.active{
                pointer-events: none;
                background: $MidnightExpress;
                color: $LinkWater;
              
            }
        }
    }
}
              
/*Bootstrap css end*/
.sports-item {
    padding: 0 30px 0 37px;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    line-height: 30px;
    position: relative;
    background:#212532;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    transition: background 240ms;
    &:before {
        box-sizing: border-box;
        width: 37px;
        height: 100%;
        position: absolute;
        top: 0;
        font: 22px icomoon;
        line-height: inherit;
        color:$StormGrey;
        padding: 0;
        text-align: center;
        left: 0;
        box-shadow: 3px 0 0 rgba(255,255,255,0.15) inset;
    }
    &:hover{
        background: #2e323e;
        color: $LinkWater;
        >p{
            color: $LinkWater;
        }
    }
    // &.active{
    //     >p{
    //         color:#fffc;
    //     }
    //     &:hover{
    //          background: #2e323e;
    //     }
    //     .arrow-view{
    //         &:before{
    //             // background:gold;
    //             // color:#fff;
    //             transform:unset;
    //         }
    //     }
    // }
    >p {
        width: 100%;
        font-size: 14px;
        line-height: inherit;
        color: $LinkWater;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
    }
}
.nationalKeno{
    &:before {
        //content: "\e911";
        content: url('../static/images/92.png') ;
    }
//    &.active{
//     &:before {
//         box-shadow: 3px 0 0 gold inset;
//         color: gold;
//     }
//   }
}
.quickLotteries{   
    &:before {
        content: "\e912";
    }
}
.favorites{
    &:before {
        content: "\e911";
    }
}
.show-more{
    background: $MidnightExpress2 ;
   &:hover{
    background: $BlueZodiac;
   }
    .sport-games-count{
        padding: 0 12px 0 0;
    }
    .arrow-view{
         display:none;   
    }
    &:before {
        content: "\e905";
        font-size:24px;
        color:$LinkWater;
    }
}
.sport-games-count {
    right: 0;
    padding: 0 37px 0 0;
    position: absolute;
    top: 0;
    color: $StormGrey;
    font-size: 12px;
    line-height: inherit;
    transition: opacity 240ms;
}
.arrow-view {
    right: 0;
    width: 30px;
    height: 100%;
    position: absolute;
    top: 0;
    font: 24px icomoon;
    line-height: inherit;
    text-align: center;
    transition: color 240ms;
    &:before {
        content: "\e90f";
        color: $LinkWater;
        transform:rotateX(180deg);
        display: block;
    }
    &:hover{
        color: $LinkWater;
    }
}

.region-item-row {
    display:block;
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0;
    overflow: hidden;
}
.region-items-box {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0;
}
.full-region-info {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0 0 1px;
    &:first-child{
        margin-top: 1px;
    }
}

.region-item {
    padding: 0 30px 0 36px;
    box-sizing: border-box;
    float: left;
    width: 100%;
    height: 30px;
    //margin: 1px 0 0;
    background: $MidnightExpress2;
    position: relative;
    cursor: pointer;
    transition: background 240ms, color 240ms;
    font-size: 14px;
    line-height: 30px;
    color: $StormGrey;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .arrow-view {
        &:before {
            content: "\e90f";
            color: $LinkWater;
            transform:rotateX(180deg);
            display: none;
        }
    }
    &:hover{
        background: $MidnightExpress;
        color: $LinkWater;
        .arrow-view {
            &:before {
                display: block;
            }
        }
    }
    // &.active{
    //     background: #282c39;
    //     color: #c1c4cd;
    //     .arrow-view {
    //         &:before {
    //              transform:unset;
    //              display:block;
    //         }
    //     }
    // }
}
.flag-view {
    left: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 10px;
}
.flag-icon-c {
    width: 16px;
    height: 16px;
    image-rendering: pixelated;
    background-repeat:no-repeat;
}


.games-list-nav {
    box-sizing: border-box;
    float: left;
    width: 100%;
}

.region-item.active+.games-list-nav {
    display: block;
}

.games-list-mini-view {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0 0 1px;
    border: 2px solid $EbonyClay ;
    border-top: none;
    padding: 3px 5px 5px;
}

.competition-view {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0;
}

.competition-title, .fav-competition {
    box-sizing: border-box;
    float: left;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    height: 24px;
    background: $MidnightExpress;
    margin: 5px 0 0;
    color: $StormGrey;
    cursor:pointer;
    padding: 0 10px;
    position:relative;
    line-height:22px;
    &:hover{
        background: $EbonyClay;
        color: $LinkWater ;
    }
    >p, span {
        font-size: 12px;
        line-height: inherit;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0 5px 0 0;
        display:inline-block;
        line-height:inherit;
        }
        >span{
            margin-right:0;
        }
        .show-time{
        right: 0;
        padding: 0 7px 0 0;
        position: absolute;
        top: 0;
        font-size: 12px;
        line-height: inherit;
        color: $BrightGrey;
        letter-spacing: 0.36px;
        .icon-close{
            font-size:16px;
            line-height: 22px;
            &:hover{
                transform:scale(1.2);
                &:before{
                    color: $white !important;
                }
            }
        }
        }
}
.competition-title{
    &.active{
        pointer-events:none;
    }
}
.fav-competition{
    &.active{
       cursor:pointer;
    }
}

.competition-view:first-child>.competition-title {
    margin: 2px 0 0;
}
.competition-view:first-child>.fav-competition {
    margin: 2px 0 0;
}


