//@import "colors";

.lobby-wrapper {
  width: 100%;
  height: calc(100% - 30.547%);
  box-sizing: border-box;
  padding-top: .3%;
  #lobby-mobile {
    display: none;
  }
  #lobby-web {
   // display: block;
  }

}
#mobile-lobby-wrapper {
  display: none;
}

.info-t-title, .info-c-type {
  height: 20px;
  width: 100%;
  display: table;
  table-layout: fixed;
  padding: 0;
  margin: 0;
  color: $StormGrey;
  font-size: 13px;
  letter-spacing: 0.33px;
  background: $BlackRussianShade;
  box-sizing: border-box;
  margin: 2px 0;

  > li {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    color: $StormGrey;
    text-transform: capitalize;
  }
}

.info-c-type {
  height: 30px;
  border-radius: 2px 2px 0 0;
  background-color: #343844;
  border-bottom: 1px solid $Salem;

  > li {
    .type-item {
      padding: 0;
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      line-height: 30px;
      position: relative;
      background: transparent;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: unset;
      transition: background 240ms;

      > p {
        margin: 0;
        display: inline-block;
        position: relative;
        margin-right: 8px;

        > span {
          margin-right: 5px;
        }
      }

      .flag-icon-c {
        width: 15px;
        height: 10px;
      }

      .flag-view {
        left: 0;
        top: 0;
      }

      .favorite-view {
        right: 0px;
        width: 37px;
        height: 100%;
        position: absolute;
        top: 0;
        font: 20px icomoon;
        line-height: inherit;
        text-align: center;
        transition: color 240ms;
        cursor: pointer;
        border-left: 1px solid rgba(0, 0, 0, 0.25);

        &:before {
          content: "\e911";
          color: $StormGrey;
        }

        &:hover {
          &:before {
            color: $Alpine;
          }
        }

        &.active {
          &:before {
            color: $Alpine;
          }
        }
      }

      .arrow-view {
        border-left: 1px solid rgba(0, 0, 0, 0.25);

        &:before {
          color: $BrightGrey;
        }
      }

      &:before {
        box-sizing: border-box;
        width: 37px;
        height: 100%;
        position: absolute;
        top: 0;
        font: 22px icomoon;
        line-height: inherit;
        color: $BrightGrey;
        padding: 0;
        text-align: center;
        right: 2%;
        cursor: pointer;
        display: none;
      }
    }
  }

  // &:hover{
  //   background-color: #545B6B;
  // }
}

.date-row-filter {
  height: 38px;
  width: 100%;
  display: table;
  table-layout: fixed;
  padding: 0;
  margin: 0;
  color: $StormGrey;
  font-size: 13px;
  letter-spacing: 0.33px;
  background: $BlackRussianShade;
  box-sizing: border-box;
  padding: 1px 0;
  border-bottom: 1px solid $Salem;

  > li {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    vertical-align: middle;
    color: $LinkWater;
    cursor: pointer;
    text-transform: capitalize;
    background-color: #212532;
    text-align: center;
    border-right: 2px solid $BlackRussianShade;

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
      color: $GhostWhite;
      font-size: 12px;
      letter-spacing: 0.36px;
    }

    &.active {
      background: $Salem;
      // background: #2E323E;
      pointer-events: none;

      > div {
        > span {
          &:first-child {
            color: $GhostWhite ;
          }

          &:nth-child(2) {
            color: $GhostWhite;
          }
        }
      }
    }

    &:hover {
      background-color: #2E323E;

      > div {
        > span {
          &:first-child {
            color:$GhostWhite;
          }

          &:nth-child(2) {
            color: $GhostWhite;
          }
        }
      }
    }

    &:first-child {
      background: $Salem;
      color: $GhostWhite;
      font-size: 12px;
      letter-spacing: 0.36px;
    }

    &:last-child {
      border: none;
    }

    > div {
      display: flex;
      flex-direction: column;
      text-align: center;

      > span {
        &:first-child {
          color: $LinkWater;
          font-size: 12px;
          letter-spacing: 0.36px;
        }

        &:nth-child(2) {
          color: $StormGrey;
          font-size: 10px;
          letter-spacing: 0.3px;
        }
      }
    }

    div.active {
      background: $Salem;
      color: #FFF;
      font-size: 12px;
      letter-spacing: 0.36px;
    }
  }


}

.t-list-view {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #7b8191;
  //background-color: #181D26;
  .data-list {


  }


}

.single-list-row-container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
  /*overflow-x: hidden;*/
  overflow-x: auto
  //height: calc(100% - 31px);
  //height: -webkit-calc(100% - 31px);
}

.single-list-row {
  width: 100%;
  height: 100%;

  .single-list {
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    background: $MidnightExpress2;
    border-bottom: 2px solid $BlackRussian;
    position: relative;

    > span {
      display: none;
    }

    &:nth-child(2n + 1) {
      background: $MidnightExpress;
    }

    &:hover {
      background-color: #30374b;
      color: $white;
      cursor: pointer;
    }

    &.active {
      background-color: rgba(26, 112, 81, 0.3) !important;
      color: $white;

      > span {
        display: block;
        position: absolute;

        &:before {
          font-family: 'icomoon';
          content: "\e910";
          color: $Salem;
          font-size: 36px;
        }
      }
    }

    > ul {
      height: 100%;
      width: 100%;
      display: table;
      table-layout: fixed;
      padding: 0;
      margin: 0;
      padding-left: 1.2%;
      box-sizing: border-box;

      li {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}