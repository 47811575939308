@media only screen and (max-width: 812px){
    .game-lobby-wrapper {
       flex-direction:column;
     }
      /*...........................game section*/
    .game-container {
        width: 100%;
        margin-bottom: 0;
        padding: 0;
        background: #2e323e;
    }
    .game-container>div:first-child{
       // padding: 1.5% 0 1.5% 52px;
        padding-left: 12px;


    }
    .game-wrapper {
        padding: 0 0 0 22px;
        align-items: center;
        margin: 3% 0 1.5% 0;
    }
    .game-wrapper .ball-animation-container {
        width: 68px;
       // width: 6.4%;
        margin: 0 0 0 1%;
       
    }
    .game-wrapper .ball-animation-container .ball-animation-wrapper {
        width: 100%;
        padding: 100% 0 0 0;
        position:relative;
        box-shadow: 0 0 8px 0 #ffffff30;
        // bottom: 0;
        .drawn-number > span  {
                font-size: 14px;
        }
    }
    .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time {
        position: absolute;
        bottom: 0;
        margin-left: -2%;
    }
    .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time > span {
        font-size: 1.6vw;
    }
    .game-container .info, .game-container .round-start-time {
        float: left;
    }
    .game-container .round-start-time {
        // margin-left: 5%;
    }
    .game-container .info:before {
        font-size: 1.7vw;
    }
    .game-container .round-start-time:before {
        font-family: "icomoon";
        content: "\e912";
        font-size: 1.7vw;
    }
    .game-container .info > span, .game-container .round-start-time > span {
        font-size: 11px;
    }

    .game-wrapper .placed-bets-container {
        width: 71.38%;
        padding: 1.75%;
        // padding: 0;
        position: relative;
        margin:0;
        // margin-left: 8%;
    }
    .game-wrapper .placed-bets-container .ball-items-container {
        height: auto;
        //height: 54px;
        margin: 0;
    }
    .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper {
        width: 26px;
        height: 26px;
       // width: 4.382%;
        //height: 4.382%;
        margin: 0 0.6% 0 0;
        padding-top: 4.382%;
        position:relative;
        .ball-item {

        }
        &:last-child{
            margin:0;
        }
    }

     /*................................Remaining time animation*/
     .game-wrapper .placed-bets-container .remaining-time {
        // width: 6.4%;
        width: 9.4%;
        padding: 9.4% 0 0 0;
        position: relative;
        // margin-left: -11.5%;

        .spinner-circular {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
        }
    }
    .game-wrapper .placed-bets-container .remaining-time .spinner-circular:last-child > span {
        font-size: 2.2vw;
    }
    .game-wrapper .placed-bets-container .remaining-time .one:before {
        border: 3px solid #7b81914d;
    }
    .game-wrapper .placed-bets-container .remaining-time .two:before {
        border-top: 3px solid #C2C5CD;
        border-right: 3px solid transparent;
    }
    /*...........................balls section*/
    .balls-container{
        width: 100%;
        background: #2e323e;
        padding-left: 10px;
        justify-content: space-between;
        display: flex;
    }
    .ball-items-container {
        // padding: 0;
    }
    .ball-item-wrapper {
        width: 25px;
        height: 25px;
       // width: 5.45%;
        position: relative;
        //height: 5.45%;
        margin: 0 0.7% 1% 0;
        opacity: 0.8;
        padding: 5.45% 0 0 0;
    }
    .ball-item {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        font-size:11px;
    }
  
    .ball-item-dropped {
        bottom: 0;
        position: absolute;
    }
    .ball-item-dropped > span {
        font-size: 11px;
    }
    .game-wrapper .ball-animation-container .ball-animation-wrapper .ball-animation {
        position: absolute;
        bottom: 0;
    }
    /*...................................Placed bets section*/

    .placed-bets-info-wrapper{
        &:before{
            content: "";
            position: absolute;
            border: 3px solid #212532;
            width: 100%;
        }
    }
    .placed-bets-info-wrapper .placed-bets-info {
        padding: 4% 0 2% 10px;
    }
    .placed-bets-info-wrapper .placed-bets-count {
       display:none;
    }
    .placed-bets-info-wrapper .placed-bets-info > h1 {
        font-size: 12px;
    }
    .progress-container {
      display:none;
    }
    .placed-bets-info-wrapper span {
        color: #7b8191;
        font-size: 11px;
        font-weight: 500;
        display: block;
    }

    /*.................................Hot Numbers section*/
    .hotNumbers-container {
        background-color: transparent;
        width: 24%;
        padding: 0;
        margin-right: 20px;
    }
    .hotNumbers-container .hotNumbers-wrapper {
        width: 100%;
        background-color: #333743;
    }
    .random-select-container {
        height: auto;
        margin: 4% 0 4% 0;
        background-color: transparent;
    }
     .random-select-wrapper {
        height: auto;
        background:transparent;
    }

    .hotNumbers-items-wrapper {
    
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .hotNumbers-items {
        margin: 4% 4%;
        width: 23.55%;
        height: 23.55%;
        padding: 23.55% 0 0 0;
        position: relative;
    }
    
    .hotNumber {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        line-height:0;
    }
    .hotNumber > span {
        font-size: 1.6vw;
        font-weight: unset;
    }
    .hotNumbers-container .hotNumbers-wrapper > p {
        font-size: 2.6vw;
    }
    // .hotNumbers-container > button {
    //     padding: 7.1% 0;
    //     height: auto;
    //     font-size: 1.6vw;
    //     width: 100%;
    // }
    .random-select-container > button {
        position: relative;
        height: auto;
        padding: 7.1% 0;
        font-size: 1.6vw;
    }
    .random-select-wrapper > button {
        position: relative;
        background: #1a7051;
        height: auto;
        padding: 7.1% 0;
        font-size: 1.6vw;
    }
    .random-select-wrapper .random-select .decrement:before {
        font-size: 3.2vw;
    }
    .random-select-wrapper .random-select .increment:before {
        font-size: 3.2vw;
    }
    .random-select-wrapper .random-select {
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }
    .hotNumbers-container .hotNumbers-wrapper .h-show-more:after {
        margin-left: -5px;
    }
    .random-select-wrapper .random-select .selected-number {
        width: auto;
    }
    .random-select-wrapper .random-select .selected-number > span {
        font-size: 2vw;
    }
    .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .flag-icon-c {
        width: 22px;
    }
    .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .round-id {
        margin-right: 3%;
    }
    .placed-bets-info-wrapper span.roundNumber {
        margin-left:-20%;
    }
}

@media only screen and (max-width: 580px) {
    .placed-bets-info-wrapper .placed-bets-info > h1 {
        font-size: 10px;
    }
    .placed-bets-info-wrapper span {
        font-size: 1.8vw;
    }
}

@media only screen and (max-width:375px) {
    .game-container .info, .game-container .round-start-time {
        float: none;
        font-size:2.8vw
    }
    .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time > span {
        font-size: 3vw;
    }
    .game-container .info:before {
        font-size: 3.7vw;
    }
    .game-container .round-start-time:before {
        font-size: 3.7vw;
    }
    .game-container > div:first-child {
        padding: 1.5% 0 1.5% 15px;
    }
    .game-container .round-start-time {
        margin-left: 0;
    }
    .game-wrapper {
        width: 95%;
        padding: 0 0 0 10px;
        margin: 0% 0 1.5% 0;
    }
  
    .game-wrapper .placed-bets-container {
        width: 76.6%;
        padding: 0.75%;
        margin-left: 0;
    }
    .game-wrapper .placed-bets-container .ball-items-container {
        margin: 0 0 0 2%;
    }
    .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper {
        width: 8.615%;
        margin: 0 1.1% 1% 0px;
        padding-top: 8.615%;
       /* .ball-item {
            height: 28px;
        }*/
    }
    
    .game-wrapper .ball-animation-container {
        width: 16.45%;
    }
    .placed-bets-info-wrapper .placed-bets-info {
        padding: 2% 0 2% 15px;
    }
    .balls-container {
        //padding-right: 15px;
        padding: 0 16px 0 0;
       // padding: 0;
        flex-direction: column;
        width:100%;
    }
    .ball-items-container {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .placed-bets-info-wrapper .placed-bets-info > h1 {
      
    }
    .placed-bets-info-wrapper span {
        font-size: 11px;
    }
    .ball-item-wrapper {
        width: 9%;
        padding: 9% 0 0 0;
        margin: 0 1% 1.4% 0;
    }
    .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .flag-icon-c {
        width: 30px;
        height: 18px;
    }
    
   /*................................Remaining time animation*/
//    .remaining-time {
//     margin-left: 0;
//    }
.game-wrapper .placed-bets-container .remaining-time {
    width: 18.31%;
    padding: 18.31% 0 0 0;
    //margin-left: 0;
}
.game-wrapper .placed-bets-container .remaining-time {
    width: 18.31%;
    padding: 18.31% 0 0 0;
    position: relative;
    margin: 1% 0 1% 5%;
}
    .hotNumbers-container {
        width: 100%;
        padding: 0;
        margin-right: 0;
        border-top: 6px solid #212532;
        box-sizing: border-box;
        border-radius: 0;
        position:relative;
    }
    .hotNumbers-container .loading-wrapper .progress-circular{
        height: 25px;
    }
    .hotNumbers-container .hotNumbers-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 3% 10px;
        align-items: center;
    }
    .hotNumbers-container .hotNumbers-wrapper > p {
        font-size: 2.8vw;
        margin: 0;
        width: 47%;
        text-align: left;
    }
    .hotNumbers-items-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 52%;
    }
    .hotNumbers-items {
        margin: 0 5% 0 0;
        width: 14.25%;
        padding: 14.25% 0 0 0;
        position: relative;
    }
    .hotNumber > span {
        font-size: 0.7em;
        font-weight: unset;
    }
    .hotNumbers-container .hotNumbers-wrapper .h-show-more {
        padding: 0% 0;
        width:10%;
    }
    .hotNumbers-container > button {
        padding: 2.7% 0;
        height: auto;
        font-size: 3vw;
        width: 32%;
        position: absolute;
        top: 54%;
        left: 10px;
    }
    .hotNumbers-container .progress-circular:before {
        width:50%;
      
    }


    /*.................Random select*/
    .random-select-container {
        height: auto;
        margin: 0 0 4% 0;
        background-color: transparent;
        display: flex;
        padding: 0;
       > button {
            position: relative;
            height: auto;
            padding: 3.1% 0;
            font-size: 3vw;
            width: 32.5%;
            order:1;
            margin: 0 auto 0 10px;
        }
    }

    .random-select-wrapper {
       height: auto;
        margin: 0 0 4% 0;
        background-color: transparent;
        display: flex;
        width: 62%;
        margin: 0 10px 0 auto;
        order:2;
    }
    .random-select-wrapper > button {
        position: relative;
        padding: 4.35% 0;
        font-size: 1.6vw;
        height: auto;
        font-size: 3vw;
    }
    .random-select-wrapper .random-select {
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
    }
    .random-select-wrapper .random-select .increment:before {
        font-size: 7.2vw;
    }
    .random-select-wrapper .random-select .decrement:before {
        font-size: 7.2vw;
    }
    .random-select-wrapper .random-select .selected-number > span {
        font-size: 4vw;
    }
}