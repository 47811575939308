@media only screen and (max-width: 812px) {
   .rounds-result-mobile {
      width: 100%;
      height: auto;

      /*.............................Result Filter section start*/
      .rounds-result-filter {
         width: 100%;
         box-sizing: border-box;
         padding: 7px 13px 10px 12px;
         float: left;

         .trigger-content {
            padding: 1.64% 1.5%;
            position: relative;
            background-color: #ffffff1a;
            border-radius: 4px;
            height: auto;
            line-height: unset;
            width: auto;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            transition: background 240ms;
            margin-bottom: 1px;

            p {
               font-size: 14px;
               color: #c2c5cd;
               margin: 0;
            }
            .arrow-view {
               width: 6%;
               font: 3.5vw icomoon;
               line-height: inherit;
               right: 0;
               height: 100%;
               position: absolute;
               top: 0;
               text-align: center;
               transition: color 240ms;
            }
         }
         .filter-container {
            width: auto;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background: #ffffff26;
            padding: 10px;
         }
         .filter-content-wrapper {
            display: flex;
         }
         .filter-scrollable-section {
            width: 40%;
            margin: 0 15px 14px 0;
            // padding: 10px 15px;
            border-radius: 4px;
            background-color: #64666c;
            .trigger-content {
               background-color: #64666c;
               padding: 0 4%;

               span {
                  color: #ffffff66;
                  font-size: 12px;
               }
               p {
                  color: #eaeaea;
                  font-size: 14px;
               }
               .arrow-view {

                  padding-right: 3%;
               }
            }
         }
         .filter-items {
            display: flex;
            width: 50%;
            .filter-from,
            .filter-to {
               width: 49%;
               height: 52px;
               margin: 0 0 14px 5px;
               padding: 0 4%;
               border-radius: 4px;
               background-color: #64666c;
               position: relative;
               span {
                  color: #ffffff66;
                  font-size: 12px;
               }
               p {
                  color: #eaeaea;
                  font-size: 14px;
               }
               .arrow-view {
                  top: -4px;
                  height: 0px ;
                  padding-right: 3%;
                  transform: translateY(15%);
               }
               .arrow-view::before {
                  content: "\e900";
                  color: #fafafa;
               }
            }
         }

         .filter-btn {
            padding: 1.05% 0;
            width: 100%;
            background-color: #197051;
            text-transform: uppercase;
            outline: none;
            border-radius: 4px;
            border: none;
            color: #f3f3f3;
            font-size: 14px;
         }
      }
      /*.............................Result Filter section end*/
      .result-m {
         width: 100%;
         background: transparent;
         float: left;
         .modal-content-wrapper .t-list-view .info-t-title > div.title {
            float: left;
            width: calc(100% / 3);
         }
         .modal-content-wrapper .t-list-view {
            background: transparent;
            height: auto;
            padding: 0 8px 8px;
            box-sizing: border-box;
            float: left;

        }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul
            > li {
            display: table-cell;
            vertical-align: middle;
            width: calc(100% / 3);
         }
         .modal-content-wrapper .t-list-view .info-t-title {
            background: unset;
            opacity: unset;
            color: #a2a2a2;
            font-size: 12px;
            height: 38px;
         }
         .single-list-row .single-list {
            height: 30px;
            width: 100%;
            box-sizing: border-box;
            background: #ffffff0d;
        }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul
            > li.width-li
            .hotNumber {
            position: relative;
         }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul {
            height: 32px;
            display: flex;
            flex-wrap: wrap;
         }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul
            > li.width-li {
            width: 80%;
         }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul
            > li
            .mobile0 {
            width: calc(100% / 3);
         }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul
            > li
            .mobile1 {
            width: calc(100% / 3);
         }
         .modal-content-wrapper
            .t-list-view
            .single-list-row
            .single-list
            .Collapsible
            .Collapsible__trigger
            > ul
            > li
            .mobile2 {
            width: calc(100% / 3);
         }
      }
   }
}

@media only screen and (max-width: 375px) {
   .rounds-result-mobile {
   }
}
