@media only screen and (max-width: 812px){
    .ticket-m {
        width: 73.02%;
        height: 35.864%;
        max-height: 433px;
        min-height: 433px;

        .modal-content-wrapper {
            width: 100%;
            overflow: hidden;
            height: 90%;
            background-color: #1e2638;
           > p {
             
                padding: 2% 2%;
               
            }
          .hotNumbers-items-wrapper {
                overflow: hidden;
                overflow-y: auto;
                height: 70%;
                width: 100%;

                > ul > li:first-child {
                    opacity: 0.4;
                    color: #fff;
                    font-size: 12px;
                    padding: 0;
                }
                > ul:nth-child(2):before {
                    border-left: 1px solid #ffffff;
                    opacity: 0.1;
                    height: 97%;
                }
                > ul:nth-child(2):after {
                    border-right: 1px solid #ffffff;
                    opacity: 0.1;
                    height: 97%;
                }

                .hotNumbers-items {
                    margin: 8% 0;
                    width: 100%;
                    padding: 0.73% 0 0px 0px;
                    position: relative;

                    .hotNumber {
                        width: 27.36%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        line-height: 0;
                    }
                    .progress-container {
                        display: block; 

                   
                        width: 70%;
                        border-radius: 2px;
                        padding: 5% 0 0 0;
                        margin-left: 35%;
                      .progress-bar-wrapper {
                            height: 4px;
                            background: #ffffff1a;
                            border-radius: 2px;
                        }
                    }
                }
            }
           > button {
                margin:3% auto 0;
                width: 23.4%;
                border-radius: 4px;
                border: none;
                background: #ffffff26;
                &:hover{
                    border:none;
                    color: #C2C5CD;
                }
            }
        }
        .modal-header-w {
            background-color:#262d3c;
            border-bottom: unset;
        }
        .modal-header-w > h3 {
          font-size: 14px;
            background-color: #262d3c;
            border-bottom: unset;
            padding: 2.2% 2%;
            display: flex;
            align-items: center;
        }
        .closed-btn {
            top: 4px;
            right: 7px;
            color: #ffffff;
        }
    }
}

@media only screen and (max-width: 620px){

}

@media only screen and (max-width: 375px){
    .ticket-m {
        width: 94.67%;
        height: 45%;
        max-height:550px;
        max-height:550px;

        .modal-content-wrapper {
            width: 100%;
            overflow: hidden;
            height: 90%;
            background-color: #1e2638;
           > p {
                color: #ffffff99;
                font-size: 11px;
                line-height: 16px;
                padding: 4%;
                margin: 0;
            }
          .hotNumbers-items-wrapper {
                overflow: hidden;
                overflow-y: auto;
                height: 70%;
                width: 100%;

                > ul > li:first-child {
                    opacity: 0.4;
                    color: #fff;
                    font-size: 12px;
                    padding: 0;
                }
                > ul:nth-child(2):before {
                    border-left: 1px solid #ffffff;
                    opacity: 0.1;
                    height: 97%;
                }
                > ul:nth-child(2):after {
                    border-right: 1px solid #ffffff;
                    opacity: 0.1;
                    height: 97%;
                }

                .hotNumbers-items {
                    margin: 8% 0;
                    width: 100%;
                    padding: 0.73% 0 0px 0px;
                    position: relative;

                    .hotNumber {
                        width: 27.36%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        line-height: 0;
                    }
                    .progress-container {
                        display: block; 

                   
                        width: 70%;
                        border-radius: 2px;
                        padding: 5% 0 0 0;
                        margin-left: 35%;
                      .progress-bar-wrapper {
                            height: 4px;
                            background: #ffffff1a;
                            border-radius: 2px;
                        }
                    }
                }
            }
           > button {
                margin: 7% auto 4%;
                width: 23.4%;
                border-radius: 4px;
                border: none;
                background: #ffffff26;
                &:hover{
                    border:none;
                    color: #C2C5CD;
                }
            }
        }
        .modal-header-w {
            background-color: #262d3c;
            border-bottom: unset;
        }
        .modal-header-w > h3 {
            font-size: 14px;
            color:white;
        }
        .closed-btn {
            color: white;
        }
    }
}