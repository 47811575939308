/*...................................my version*/
.mobile-header {
  display: block;
}

@media only screen and (max-width: 812px) {
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;

  }

  .created-tickets-wrapper {
    .ticket-text {
      letter-spacing: 0.33px;
      background-color: #2e323e;
      text-align: center;
      color: #7B8191;
      overflow: visible;
      font-size: 14px;
      padding: 2px;
    }
  }

  .l-column-wrapper {
    width: 100%;
    order: 2;
  }
  .c-column-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 0.209%;
   // margin-bottom: 38px;
    order: 3;
  }
  .r-column-wrapper {
    height: 100%;
    width: 100%;
    order: -1;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .r-top-box .lottery-tab-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 2;
    border-bottom: 2px solid;
    // overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 41px;
    display: flex;
    width: 100%;
    margin-top: 0;
  }
  .lottery-tab-wrapper::-webkit-scrollbar {
    display: none;
  }
  .r-top-box .lottery-tab-wrapper .lottery_tabs,
  .r-top-box .lottery-tab-wrapper .r-tab {
    // width: calc(100% / 4);
    flex-grow: 1;
    position: relative;
    display: inline-flex;
    justify-content: center;
    border-bottom: 1px transparent;

    &.active {
      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        margin: 0 auto;
        animation: mymove 0.4s forwards;
        border-bottom: 1px solid #a71f67;

        @keyframes mymove {
          from {
            width: 0;
          }

          to {
            width: 100%;
          }
        }
      }
    }
  }
  .r-top-box .r-tab {
    padding: 0.71% 3.5%;
  }
  .r-top-box .r-tab .r-text {
    will-change: scroll-position;
    //overflow: hidden;
    white-space: nowrap;
    //text-overflow: ellipsis;
    background-color: unset;
    font-size: 13px;

    span {
      margin-left: 0;
    }

    &:before {
      content: "";
    }
  }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0;
  }
  .r-top-box .r-tab .r-text:before {
    content: "";
  }

  /*..............................Competition header*/
  .l-full-menu-wrapper-box
  .Collapsible
  .Collapsible__contentInner
  .competition-title.active {
    background-color: #ffffff1c;
  }
}

@media only screen and (max-width: 375px) {
  .r-top-box .lottery-tab-wrapper .lottery_tabs,
  .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    width: auto;
  }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0;
    font-size: 12px;
    //font-size: 2.6vw;
  }

  /*............................competitions header*/
  .favorites:before {
    padding-left: 0;
  }
  .favorites p {
    padding-left: 0;
  }
  /*.competition-title > p,
  .competition-title span,
  .fav-competition > p,
  .fav-competition span {
     font-size: 12px;
     //font-size: 2.8vw;
  }*/
  .sports-item:before {
    font-size: 5vw;
  }
  .sports-item > p {
    font-size: 14px;
    //font-size: 2.8vw;
  }
}
