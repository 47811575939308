@import "colors";

.alertContainer {
  height: 100%;
  width: 100%;
  z-index: 10000;
  display:flex;
  background: rgba(0,0,0,0.4);
  .alertDiv {
    //background-color: #2E323E;
    background-color: #353a49;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    margin:auto;
    border: 1px solid #1d8a63;
    border-radius: 2px;
    width: 31%;
    height: auto;
    box-sizing: border-box;
    position: relative;

    .header {
      background-color: #212532;
      height: 85px;
      text-align: left;
      text-indent: 8%;
      line-height: 85px;
      width: 100%;
      font-size: 20px;
      text-transform: uppercase;
      padding: 0;
      //border-bottom: 1px solid #0E141A;
      //border-radius: 2px 2px 0 0;
      color: #e8e8ea;
    }

    .informationDiv {
      width: 100%;
      text-align: center;
      padding: 5% 0 5%;
      overflow: auto;
      box-sizing: border-box;

      p {
        color: #e8e8ea;
        font-size: 22px;
        font-weight: 500;
        display: inline-block;
      }

      .bigFont {
        color: #fff;
        font-size: 32px;
        font-weight: bold;
        margin-top: 10px;
        display: block;
        vertical-align: middle;
        text-transform: uppercase;
      }
    }

    .buttonsContainer {
      width: 100%;
      text-align: center;
      position: relative;
      margin: 0 0 5% 0;
      //&:before {
      //    background: $BlackRussian;
      //    box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.1);
      //    content: "";
      //    height: 1px;
      //    width: 80%;
      //    display: inline-block;
      //}

      >button {
        box-sizing: border-box;
        height: 35px;
        clear: both;
        margin: auto;
        width: 120px;
        padding: 0 20px;
        display: block;
        border: none;
        border-radius: 2px;
        background: #2bbb87;
        text-transform: uppercase;
        color: #e8e8ea;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
        outline: none;

        &:hover {
            background: #2dc38d;
        }

        // &.red {
        //   &:hover {
        //     background: #a82d46;
        //   }
        // }
      }
    }
  }
}

