@import "colors";

body {
   width: 100%;
   height: 100%;
  // min-width: 980px  !important;
   font-family: Roboto, sans-serif;
   position: absolute;
   //overflow-x: hidden;
   background: $BlackPearl;
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
   -webkit-overflow-scrolling: touch;
   margin: 0;
}

.scroll {
   overflow-y: auto;
   height: 86%;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
}

#root {
   width: 100%;
   height: 100vh;
 //    max-width: 1920px;
  // min-width: 1340px;
   overflow: hidden;
   user-select: none;
   transform-origin: top left;
   position: relative;
   @media only screen and (max-width: 1440px) {
      //transform: none!important;
   }
   > div {
      width: 100%;
      height: 100%;
      position: relative;
   }
}

.button-view {
   background: $Salem;
   border: none;
   color: $white;
   display: block;
   padding: 6% 0;
   width: 100%;
   font-size: 11px;
   cursor: pointer;
   border-radius: 2px;
   border: none;
   text-transform: uppercase;
   transition: background 240ms, box-shadow 240ms, color 240ms;
   text-decoration: none;
   outline: none !important;
   letter-spacing: 0.36px;
   margin: 10% auto 0;
   &:hover {
      background: #349572;
   }
   &.disabled {
      background: #1b2137;
      color: #505767;
   }
}

.avoid-clicks {
   pointer-events: none;
   opacity: 0.6;
}

//.currency {
//font-family: "icomoon";
//left: 10px;
//}
.AMD {
   &:after {
      content: "\77";
   }
}
.EUR {
   &:after {
      font-family: icomoon;
      content: "\79";
   }
}
.USD {
   &:after {
      font-family: icomoon;
      content: "\78";
   }
}
.RUB {
   &:after {
      font-family: icomoon;
      content: "\7a";
   }
}
.TRY {
   &:after {
      content: "A";
   }
}
.C {
   &:after {
      font-family: icomoon;
      content: "\76";
   }
}
.coin {
   &:after {
      font-family: icomoon;
      content: "\76";
   }
}

/*** iPhone and iOS Form Input Zoom Fixes older than 5, version 1***/
// @media screen and (device-aspect-ratio: 2/3) {
//   select, textarea, input[type="text"], input[type="password"],
//   input[type="datetime"], input[type="datetime-local"],
//   input[type="date"], input[type="month"], input[type="time"],
//   input[type="week"], input[type="number"], input[type="email"],
//   input[type="url"]{ font-size: 16px; }
// }

// zoom out mobile version 2
// @media screen and (max-width: 767px) {
//   input, select, textarea {
//     font-size: 16px;
//   }
// }

*::-webkit-scrollbar {
   width: 4px;
   border-radius: 3px;
   background: #212531;
}
*::-webkit-scrollbar-thumb {
   border-radius: 5px;
   background: #505767;
   transition-duration: 0.2s;
}
*::-webkit-scrollbar-thumb:hover {
   background: #5d6577;
}
*::-webkit-scrollbar-track {
   border-radius: 5px;
}
*::-webkit-scrollbar-track-piece {
   background: transparent;
}

* {
   scrollbar-width: thin;
   scrollbar-color: #212531 #505767;
}
