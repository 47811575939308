@media only screen and (max-width: 812px) {
  body {
    background-color: #212532;
  }

  #root {
    max-width: 812px;
    min-width: 375px;
    overflow-y: unset;
    height: auto;
  }

  .app {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .app > div {
    width: 100%;
  }

  .modal-wrapper {
    background-color: #1e2638;
  }
  .popups-container {
    height: 100%;
    background: rgba(0, 0, 0, 0.4);

    .alertContainer {
      height: 100vh;
      background: transparent;

      .alertDiv {
        border: none;
        width: 40%;
        height: auto;

        .header {
          height: auto;
          line-height: unset;
          font-size: 2.2vw;
          padding: 15px 0;
        }

        .informationDiv p {
          font-size: 2.5vw;
        }
      }
    }
  }

  .mobile-bottom {
    height: auto;
    width: 100%;
    background: #2e323e;
    position: relative;
    order: 4;
    // margin-top: 1.2%;
    padding: 2% 0;

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    cursor: pointer;
    // position: absolute;
    bottom: 0;
    // z-index: 9999999;
    &.no-bet {
      opacity: 0.5;
      pointer-events: none;
    }

    button {
      outline: none;
      color: #FFFFFF;
      border: none !important;
      width: 90%;
      background-color: #1a7051;

    }

    /*  &:before {
        content: "";
        position: absolute;
        width: 5.5%;
        height: 0px;
        opacity: 0.3;
        border: solid 3px #ffffff;
        border-radius: 8px;
        top: 7px;
      }*/

    .ticket-item-wrapper {
      .percent-bar-wrapper {
        padding-bottom: 1%;
      }

      .t-balls {
        width: auto;
        float: none;
      }

      .ticket-item > div {
        padding-top: 7px;
        float: left;
      }

      .ticket-item {
        padding-left: 10px;
        height: 39px;
        width: 100%;
        padding: 0;


        .country {
          float: left;
        }

        .delete-wrapper {
          float: right;
          padding: 6px 21px 0px;
        }

        .ball-items-container {
          // padding-top: 11px;
        }

        .delete-wrapper {
          // padding: 0;
          //padding: none;
        }

      }

      .ball-items-container {
        padding-top: 2px;
        padding-left: 6px;
        width: 100%;
        // width: 70%;
        //height: 35px;
        //background: #00000033;
        margin: auto;
        border-radius: 19px;
        box-sizing: border-box;

        .ball-item-wrapper {
          padding-top: 2px;
          width: 25px;
          height: 25px;
          // width: 5.5%;
          //height: 20px;
          margin: 1% 0.5%;
        }
      }

    }

    .ticket-number-wrapper {
      float: left;

      .ticket {
        float: left;
      }
    }

    .ticket-full-menu > button {
      padding: 1.5% 0;
    }

    .ticket-full-menu {
      .button-view {
        width: 100%;
        margin: 5px 0 0 0;
      }
    }

    .ticket-full-menu {
      .system-wrapper {
        //border-top: none;
        width: 95%;
        float: left;
        text-align: start;
        border-bottom: 1px solid #464750;
        border-top: 1px solid #464750;
        padding: 1% 0;
        //color: #ffff;

        .system-item {
          div {
            width: 67%;
            border-radius: 4px;
            height: 26px;
          }

          select {
            //color: #FFFFFF;
          }

          span {
            //color: #FFFFFF;
          }
        }

        .arrow {
          right: 4px;
        }

      }

      .amount-container {
        margin-bottom: 2%;
        border: none;

        .maxBet {
          width: 8%;
          margin-right: 0;
        }

        div {
          width: 92%;
        }
      }

      .amount-container > div {
        //border: none;
      }

    }

    .possible-winnings {
      border-top: none;
      width: 100%;
      float: right;
      padding: 0;

      .button-view {
        margin: 0;
      }

      .amount-container {
        .maxBet {
          width: 15%;
        }
      }

      .amount-container > div {
        width: 80%;
      }
    }

  }
  .bottom-none {
    display: none;
  }
  /*.................Loading */
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div.loading-wrapper {
    width: 100%;

    .progress-circular {
      width: 30px;
    }
  }
  .region-item-row {
    .region-item {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 620px) {
  .popups-container {
    .alertContainer {
      .alertDiv {
        width: 50%;

        .header {
          font-size: 2.5vw;
          padding: 15px 0;
        }

        .informationDiv p {
          font-size: 3vw;
        }

        .alertContainer .alertDiv .buttonsContainer > button {
          height: auto;
          width: 37%;
          padding: 2.2% 0;
          font-size: 2.4vw;
        }
      }
    }
  }

}

@media only screen and (max-width: 375px) {
  .popups-container {
    .alertContainer {
      .alertDiv {
        .header {
          font-size: 3vw;
          padding: 10px 0;
        }

        .informationDiv p {
          font-size: 3.5vw;
        }
      }
    }
  }

  .mobile-bottom:before {
    width: 11%;
    margin-left: -5%;
  }
}


