@import "colors";

.popups-container {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0;
}

.popup-wrapper {
  //position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  //animation: 100ms popup-bg linear;
}

@keyframes popup-bg {
  0% {
    background: rgba(13, 17, 33, 0);
  }
  100% {
    background: rgba(13, 17, 33, 0.84);
  }
}

.modal-wrapper {
  border-radius: 4px;
  position: relative;
  background-color: #2E323E;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: auto;

  .closed-btn {
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 20px;
    color: #7B8191;

    &:before {
      font-family: "icomoon";
      content: "\e901";
      font-size: 24px;
    }

    &:hover {
      color: #ffffffb5;
    }
  }
}

.ticket-m {
  width: 23%;
  height: 60%;
  // overflow-y: scroll;
  .modal-content-wrapper {
    width: 100%;
    overflow: hidden;
    height: 100%;

  }
  .modal-content-wrapper .hotNumbers-items-wrapper {
    overflow: hidden;
    overflow-y: auto;
    height: 68%;
}
.modal-content-wrapper > button {
  margin: 6% auto;
  }
}

.result-m {
  width: 52.396%;

  .modal-header-w {
    padding: 2%;

    > span {
      width: 22px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  .modal-content-wrapper > p {
    padding: 1.89%;
  }
}

.modal-header-w {
  width: 100%;
  padding: 4.7% 4%;
  border-radius: 2px 2px 0 0;
  background-color: #212532;
  display: table;
  box-sizing: border-box;
  border-bottom: 1px solid #0E141A;

  > h3 {
    color: #C2C5CD;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
}

.modal-content-wrapper {
  width: 100%;
  overflow: hidden;
  height: 90%;
  overflow-y: scroll;

  > p {
    color: #7B8191;
    font-size: 14px;
    line-height: 16px;
    padding: 4%;
    margin: 0;
  }

  .hotNumbers-items-wrapper {
    overflow: hidden;

    > ul {
      float: left;
      margin: 0;
      width: 25%;
      padding: 0 4%;
      position: relative;

      &:nth-child(2) {
        > li {
          .progress-bar {
            //background: linear-gradient(-145deg, #F0B74D, #AA8136);
          }
        }

        &:after {
          content: "";
          border-right: 1px solid #0E141A;
          opacity: 0.2;
          position: absolute;
          right: 0;
          bottom: 0;
          height: 90%;
          display: block;
        }

        &:before {
          content: "";
          border-left: 1px solid #0E141A;
          opacity: 0.2;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 90%;
          display: block;
        }
      }

      &:nth-child(3) {
        > li {
          .progress-bar {
            //background: linear-gradient(-145deg, #F06A4D, #F04D4D);
          }
        }
      }

      > li {
        list-style-type: none;
        text-align: center;
        box-sizing: border-box;
        padding: 9.37% 0;

        &:first-child {
          opacity: 0.8;
          color: #7B8191;
          font-size: 12px;
        }
      }
    }
  }

  .t-list-view {
    background: #2E323E;
    height: auto;
    padding: 0 8px 8px;
    box-sizing: border-box;
    float: left;

    li {
      width: 2.5%;
    }

    .info-t-title {
      background: unset;
      opacity: 0.4;
      color: #7B8191;
      font-size: 12px;
      height: 38px;

      > div {
        &.title {
          padding-left: 10px;
          float: left;
          width: 10%;
        }

        &.width-li {
          width: 59%;
        }

        //    &:nth-child(3){
        //     padding: 0 4%;
        // }
      }
    }

    .single-list-row {
      overflow-y: auto;
      max-height: 452px;

      .list {
        > div {
          float: left;
          width: 30%;
        }
      }

      .single-list {
        margin-bottom: 2px;
        border: none;
        position: relative;
        height: auto;
        float: left;

        .ticket-item-wrapper {
          height: auto;
          width: 100%;
          float: left;
          position: relative;
          border-radius: unset;
          box-shadow: unset;
          background: #181B24;
          margin-bottom: unset;
          box-sizing: border-box;
          cursor: auto;
          padding-bottom: 1.5%;

          &:first-child {
            padding-top: 1.5%;
          }

          .ticket-item {
            width: 40%;
            box-sizing: border-box;
            height: 100%;
            float: left;
            padding: 0;

            > div {
              float: left;
              position: relative;
              padding: 0 15px 0 20px;
              width: 40%;

              &:nth-child(2) {
                border-left: 1px solid #3a3f52;
                width: 30%;
              }

              .currency, .country {
                color: #C2C5CD;
                font-size: 14px;
              }
            }
          }

          .t-balls {
            width: 60%;
            padding: 0;

            .ball-items-container {
              box-sizing: border-box;
              padding-left: 6%;
              display: flex;
              flex-wrap: nowrap;
              width: auto;

              .ball-item-wrapper {
                width: 20px;
                height: 20px;
                margin: 0 3px;

                .ball-item {
                  pointer-events: none !important;
                }

              }
            }

            &:before {
              height: 35px;
              background: #3a3f52;
            }
          }
        }

        .Collapsible {
          .Collapsible__trigger.is-open {
            > ul {
              cursor: auto;
             // background-color: #181B24;

              > li {
                border-bottom: 1px solid #0E141A;
              }
            }

          }

          .Collapsible__trigger {

            > ul {
              height: 38px;
              width: 100%;
              display: table;
              table-layout: fixed;
              margin: 0;
              padding: 0 1.2%;
              box-sizing: border-box;

              > li {
                display: table-cell;
                vertical-align: middle;
                // &:nth-child(3){
                //     padding: 0 4%;
                // }
                &:first-child {
                  //display: none;
                }

                &:last-child {
                  width: 0.1%;
                  // display: none;
                }

                &.width-li {
                  width: 15%;

                  &.li {
                    > div {
                      margin: 10px;
                    }
                  }

                  .hotNumber {
                      padding: 0;
                      width: 22px;
                      height: 22px;
                      float: left;
                      margin-right: 4.8px;
                  }
                }

              }

            }

            .arrow-view {
              height: unset;

              &:before {
                color: #505767;
              }

            }

          }

        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: #181B24;
          color: #7B8191;
        }

        &.active {
          background: #181B24;
          color: #7B8191;
        }
      }
    }
  }

  > button {
    box-sizing: border-box;
    height: 35px;
    clear: both;
    margin: 8% auto;
    width: 160px;
    display: block;
    border: 1px solid #7B8191;
    background: unset;
    text-transform: uppercase;
    color: #C2C5CD;
    font-size: 14px;
    font-weight: 500;
    outline: none;

    &:hover {
      border: 1px solid #9d7f44;
      color: #9d7f44;
    }
  }


}


/* ticket_modal start*/

/* ticket_modal end*/


/* result_modal start*/

/* result_modal end*/