@media only screen and (max-width: 812px) {

  /*filter section start*/
  .filter-view {
      display: flex;
      align-items: center;
      padding-left: 52px;
   
    .filter-row {
      order: 1;
      width: 18.21%;
      height: 26px;
      min-width:95px;

      .time-filter {
        width: 100%;
        background-color: #4d515b;
        border-radius: 4px;
        padding: 0;

        &:before {
          color: #ffff;
          font-size: 1.3em;
          background-color: #4d515b;
          z-index: 99999;
          height: 100%;
          width: 0;
          left: 3%;
        }

        select {
          border: none;
          outline: none;
          box-shadow: none;
          background-color: #4d515b;
          width: 64%;
          height: 100%;
          right: 0;
          position: absolute;
          color: #ffff;
          padding: 0px 18%;

          option {
            background-color: #4d515b;
            color: #ffff;
          }
        }
      }

    }

    .second-row-filter {
      order: 2;
      width: 38%;
      min-width: 150px;
      padding: 0;
      background-color: #2E323E;
      border-radius: 4px;
      margin: 0 8px;
      height: 26px;

      .search-box {
        height: 24px;
        .block-search {
          border-radius: 4px;
        }
        .search-results-container {
           .games-list-mini-view {
             padding: 0;
             .competition-title {
               margin: 1px 0 0;
             }
           }
        }

        &:before {
          color: #ffffffa8;
          height: 100%;
          right: 2%;
          top: -8%;
        }
      }
    }
    .r-tab {
      width: 15%;
      order: 3;
     .r-text {
        height: 20px;
      }
    }
  }
}

@media only screen and (max-width:375px) {
 
 /*............................search-filter header*/
  .filter-view {
    padding-left: 15px;
    .r-tab {
      margin-right: 21px;
    }
  }
  .filter-view .filter-row {
    width: 39%;
}
.filter-view .second-row-filter {
  width: 61.37%;
}
 .search-box > form {
  border-radius: 4px;
}
.filter-view .second-row-filter .search-box:before {
  color: #fffc;
  font-size: 5.2vw;
  top: -2px;
}
.filter-view .filter-row .time-filter select {
  color: #cccccc;
}
.filter-view .filter-row .time-filter:before {
  font-size: 1.2em;
}

}
