$white: #fff;
$black: #000;
$BlackPearl: #0E141A; /* overall design background */
$Mischka: #A5AABC; /* live */
$LinkWater : #C2C5CD; /* All, Quick lotteries, lotteries*/
$StormGrey : #7B8191; /* search for keno , 1578 , Spain, UK Keno ... */
$EbonyClay : #2E323E; /* live- background */
$MidnightExpress : #212532; /* All- background */
$whiteShade : unquote("#ffffff08"); /* National keno - background */
$BlueZodiac : #3F4553; /* Search for keno border color - background */
$Vulcan : #353844; /* Uk keno 6/59 - background */
$BlackRussian : #121623; /* black borders */
$GhostWhite : #F3F3F5; /* Pre-match */
$JazzberryJam : #A71F67; /* Pre-match - background */
$SalemShade : unquote("#1a70514d"); /* green color shade - background */
$Salem : #1A7051; /* All - green  - background */
$MidnightExpress2 : #282D3D; /* to the blue - background */
$BrightGrey : #545B6B; /* to the blue - background */
$BlackShade : unquote("#00000033"); /* lottery circle place - background */
$Lochmara : #326CA1; /* lottery circle border color */
$BlackRussianShade : #171C26; /* Round ID - background */
$MediumCarmine : #B73C3C; /* Clear button - background */ 
$ElfGreen : #249E71; /* linear green short rows 100% - background (Salem color will be 0%)*/ 
$Ronchi : #F0B74D; /* linear yellow short rows 100% - background */ 
$Alpine : #AA8136; /* linear yellow short rows 0% - background */ 
$BurntSienna : #F06A4D; /* linear coral short rows 0% - background */ 
$SunsetOrange : #F04D4D; /* linear coral short rows 100% - background */ 
$blackShadow : unquote("#0009"); /* popup parent - background */ 
$Gainsboro : #D8D8D8; /* lottery big circle - background / Artboard Copy 6*/ 
$DarkGray : #AEAEAE; /* lottery small circle - background / Artboard Copy 6*/ 
$VulcanShade : #333743; /* Hot Numbers opacity color - background / Artboard Copy 6*/
$bluie: #1b2137;