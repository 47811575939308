@import url(../static/fonts/icomoon/style.css);
/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
body {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  position: absolute;
  background: #0E141A;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  -webkit-overflow-scrolling: touch;
  margin: 0; }

.scroll {
  overflow-y: auto;
  height: 86%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  transform-origin: top left;
  position: relative; }
  #root > div {
    width: 100%;
    height: 100%;
    position: relative; }

.button-view {
  background: #1A7051;
  border: none;
  color: #fff;
  display: block;
  padding: 6% 0;
  width: 100%;
  font-size: 11px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  text-transform: uppercase;
  transition: background 240ms, box-shadow 240ms, color 240ms;
  text-decoration: none;
  outline: none !important;
  letter-spacing: 0.36px;
  margin: 10% auto 0; }
  .button-view:hover {
    background: #349572; }
  .button-view.disabled {
    background: #1b2137;
    color: #505767; }

.avoid-clicks {
  pointer-events: none;
  opacity: 0.6; }

.AMD:after {
  content: "\77"; }

.EUR:after {
  font-family: icomoon;
  content: "\79"; }

.USD:after {
  font-family: icomoon;
  content: "\78"; }

.RUB:after {
  font-family: icomoon;
  content: "\7a"; }

.TRY:after {
  content: "A"; }

.C:after {
  font-family: icomoon;
  content: "\76"; }

.coin:after {
  font-family: icomoon;
  content: "\76"; }

/*** iPhone and iOS Form Input Zoom Fixes older than 5, version 1***/
*::-webkit-scrollbar {
  width: 4px;
  border-radius: 3px;
  background: #212531; }

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #505767;
  transition-duration: 0.2s; }

*::-webkit-scrollbar-thumb:hover {
  background: #5d6577; }

*::-webkit-scrollbar-track {
  border-radius: 5px; }

*::-webkit-scrollbar-track-piece {
  background: transparent; }

* {
  scrollbar-width: thin;
  scrollbar-color: #212531 #505767; }

.lobby-wrapper {
  width: 100%;
  height: calc(100% - 30.547%);
  box-sizing: border-box;
  padding-top: .3%; }
  .lobby-wrapper #lobby-mobile {
    display: none; }

#mobile-lobby-wrapper {
  display: none; }

.info-t-title, .info-c-type {
  height: 20px;
  width: 100%;
  display: table;
  table-layout: fixed;
  padding: 0;
  margin: 0;
  color: #7B8191;
  font-size: 13px;
  letter-spacing: 0.33px;
  background: #171C26;
  box-sizing: border-box;
  margin: 2px 0; }
  .info-t-title > li, .info-c-type > li {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    color: #7B8191;
    text-transform: capitalize; }

.info-c-type {
  height: 30px;
  border-radius: 2px 2px 0 0;
  background-color: #343844;
  border-bottom: 1px solid #1A7051; }
  .info-c-type > li .type-item {
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    line-height: 30px;
    position: relative;
    background: transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: unset;
    transition: background 240ms; }
    .info-c-type > li .type-item > p {
      margin: 0;
      display: inline-block;
      position: relative;
      margin-right: 8px; }
      .info-c-type > li .type-item > p > span {
        margin-right: 5px; }
    .info-c-type > li .type-item .flag-icon-c {
      width: 15px;
      height: 10px; }
    .info-c-type > li .type-item .flag-view {
      left: 0;
      top: 0; }
    .info-c-type > li .type-item .favorite-view {
      right: 0px;
      width: 37px;
      height: 100%;
      position: absolute;
      top: 0;
      font: 20px icomoon;
      line-height: inherit;
      text-align: center;
      transition: color 240ms;
      cursor: pointer;
      border-left: 1px solid rgba(0, 0, 0, 0.25); }
      .info-c-type > li .type-item .favorite-view:before {
        content: "\e911";
        color: #7B8191; }
      .info-c-type > li .type-item .favorite-view:hover:before {
        color: #AA8136; }
      .info-c-type > li .type-item .favorite-view.active:before {
        color: #AA8136; }
    .info-c-type > li .type-item .arrow-view {
      border-left: 1px solid rgba(0, 0, 0, 0.25); }
      .info-c-type > li .type-item .arrow-view:before {
        color: #545B6B; }
    .info-c-type > li .type-item:before {
      box-sizing: border-box;
      width: 37px;
      height: 100%;
      position: absolute;
      top: 0;
      font: 22px icomoon;
      line-height: inherit;
      color: #545B6B;
      padding: 0;
      text-align: center;
      right: 2%;
      cursor: pointer;
      display: none; }

.date-row-filter {
  height: 38px;
  width: 100%;
  display: table;
  table-layout: fixed;
  padding: 0;
  margin: 0;
  color: #7B8191;
  font-size: 13px;
  letter-spacing: 0.33px;
  background: #171C26;
  box-sizing: border-box;
  padding: 1px 0;
  border-bottom: 1px solid #1A7051; }
  .date-row-filter > li {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    vertical-align: middle;
    color: #C2C5CD;
    cursor: pointer;
    text-transform: capitalize;
    background-color: #212532;
    text-align: center;
    border-right: 2px solid #171C26; }
    .date-row-filter > li.disabled {
      pointer-events: none;
      opacity: 0.6;
      color: #F3F3F5;
      font-size: 12px;
      letter-spacing: 0.36px; }
    .date-row-filter > li.active {
      background: #1A7051;
      pointer-events: none; }
      .date-row-filter > li.active > div > span:first-child {
        color: #F3F3F5; }
      .date-row-filter > li.active > div > span:nth-child(2) {
        color: #F3F3F5; }
    .date-row-filter > li:hover {
      background-color: #2E323E; }
      .date-row-filter > li:hover > div > span:first-child {
        color: #F3F3F5; }
      .date-row-filter > li:hover > div > span:nth-child(2) {
        color: #F3F3F5; }
    .date-row-filter > li:first-child {
      background: #1A7051;
      color: #F3F3F5;
      font-size: 12px;
      letter-spacing: 0.36px; }
    .date-row-filter > li:last-child {
      border: none; }
    .date-row-filter > li > div {
      display: flex;
      flex-direction: column;
      text-align: center; }
      .date-row-filter > li > div > span:first-child {
        color: #C2C5CD;
        font-size: 12px;
        letter-spacing: 0.36px; }
      .date-row-filter > li > div > span:nth-child(2) {
        color: #7B8191;
        font-size: 10px;
        letter-spacing: 0.3px; }
    .date-row-filter > li div.active {
      background: #1A7051;
      color: #FFF;
      font-size: 12px;
      letter-spacing: 0.36px; }

.t-list-view {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #7b8191; }

.single-list-row-container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: scroll;
  /*overflow-x: hidden;*/
  overflow-x: auto; }

.single-list-row {
  width: 100%;
  height: 100%; }
  .single-list-row .single-list {
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    background: #282D3D;
    border-bottom: 2px solid #121623;
    position: relative; }
    .single-list-row .single-list > span {
      display: none; }
    .single-list-row .single-list:nth-child(2n + 1) {
      background: #212532; }
    .single-list-row .single-list:hover {
      background-color: #30374b;
      color: #fff;
      cursor: pointer; }
    .single-list-row .single-list.active {
      background-color: rgba(26, 112, 81, 0.3) !important;
      color: #fff; }
      .single-list-row .single-list.active > span {
        display: block;
        position: absolute; }
        .single-list-row .single-list.active > span:before {
          font-family: 'icomoon';
          content: "\e910";
          color: #1A7051;
          font-size: 36px; }
    .single-list-row .single-list > ul {
      height: 100%;
      width: 100%;
      display: table;
      table-layout: fixed;
      padding: 0;
      margin: 0;
      padding-left: 1.2%;
      box-sizing: border-box; }
      .single-list-row .single-list > ul li {
        display: table-cell;
        vertical-align: middle; }

/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
.c-column-wrapper {
  width: 68.542%;
  height: 100%;
  float: left;
  margin: 0 0.209%; }

.game-lobby-wrapper {
  width: 100%;
  height: 30.547%;
  display: flex; }
  .game-lobby-wrapper > div {
    height: 100%; }

/*Keno start*/
#mobile-game-container {
  display: none; }

.game-container {
  width: 49.089%;
  margin-right: 0.305%;
  box-sizing: border-box;
  border-radius: 2px;
  background: linear-gradient(45deg, #212532 100%, #353844 0%);
  padding: 0.8% 0.8% 2%; }
  .game-container .info,
  .game-container .round-start-time {
    color: #7B8191;
    font-size: 14px;
    display: table;
    padding: 0.2% 0; }
    .game-container .info > span,
    .game-container .round-start-time > span {
      display: table-cell;
      vertical-align: middle;
      padding-left: 5px; }
  .game-container .info:before {
    font-family: "icomoon";
    content: "\e908";
    font-size: 1.1vw; }
  .game-container .round-start-time > span:last-child {
    font-weight: 500;
    padding-left: 10%; }
  .game-container .round-start-time:before {
    font-family: "icomoon";
    content: "\e912";
    font-size: 1.1vw; }

.startAnimation {
  animation: ball 2.5s 1 forwards; }

.game-wrapper {
  width: 100%;
  height: 50.16%;
  display: flex;
  padding: 3% 0 0; }
  .game-wrapper .placed-bets-container {
    width: 61.294%;
    height: 60%;
    background: #00000033;
    margin: auto;
    border-radius: 19px;
    box-sizing: border-box;
    position: relative; }
    .game-wrapper .placed-bets-container .ball-items-container {
      width: 100%;
      height: 100%;
      margin: 2px 0px 0px 4px;
      padding: 0; }
      .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper {
        width: 8.95%;
        height: 43.59%;
        margin: 0 1% 0 0; }
        .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper .ball-item {
          pointer-events: none; }
    .game-wrapper .placed-bets-container .remaining-time {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      padding-left: 1%;
      box-sizing: border-box; }
      .game-wrapper .placed-bets-container .remaining-time .spinner-circular {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        position: absolute; }
        .game-wrapper .placed-bets-container .remaining-time .spinner-circular:last-child {
          display: table;
          text-align: center; }
          .game-wrapper .placed-bets-container .remaining-time .spinner-circular:last-child > span {
            display: table-cell;
            vertical-align: middle;
            color: #C2C5CD; }
      .game-wrapper .placed-bets-container .remaining-time .one:before {
        content: "";
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 5px solid #7b81914d; }
      .game-wrapper .placed-bets-container .remaining-time .two:before {
        content: "";
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border-top: 5px solid #C2C5CD;
        border-right: 5px solid transparent;
        animation: spinner 1200ms linear infinite; }

@keyframes spinner {
  100% {
    transform: rotate(360deg); } }
  .game-wrapper .ball-animation-container {
    margin: auto;
    height: 100%;
    width: 39.516%; }
    .game-wrapper .ball-animation-container .ball-animation-wrapper {
      height: 100%;
      width: 53.07%;
      background: radial-gradient(circle, #121623 0%, #121623 97%, rgba(14, 20, 26, 0) 100%);
      margin: auto;
      border-radius: 50%;
      padding: 4%;
      box-sizing: border-box; }
      .game-wrapper .ball-animation-container .ball-animation-wrapper .ball-animation {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url("../static/images/bigBall.png");
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        animation: ball 2.5s 1 forwards; }

@keyframes ball {
  0% {
    margin-top: -130px;
    opacity: 1; }
  35% {
    margin-top: 0;
    opacity: 1; }
  70% {
    margin-top: 0;
    opacity: 1; }
  100% {
    opacity: 0; } }
        .game-wrapper .ball-animation-container .ball-animation-wrapper .ball-animation .drawn-number {
          display: flex;
          width: 100%;
          height: 100%; }
          .game-wrapper .ball-animation-container .ball-animation-wrapper .ball-animation .drawn-number > span {
            font-size: 17px;
            font-weight: 700;
            transform: rotate(-10deg);
            margin: auto; }
      .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time {
        width: 100%;
        height: 100%;
        text-align: center; }
        .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time:before {
          content: ""; }
        .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time > span {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          padding: 0; }

.placed-bets-info-wrapper {
  width: 100%;
  height: 20%; }
  .placed-bets-info-wrapper span {
    color: #7b8191;
    font-size: 12px;
    font-weight: 500;
    display: block; }
  .placed-bets-info-wrapper .placed-bets-info {
    width: 60.484%;
    float: left;
    height: 100%; }
    .placed-bets-info-wrapper .placed-bets-info > h1 {
      color: #C2C5CD;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      margin: 0; }
    .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper {
      width: 100%;
      display: flex; }
      .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .flag {
        margin: 1.5% 3% 0 0;
        position: relative; }
      .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .flag-icon-c {
        width: 28px;
        height: 22px; }
      .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .round-id {
        margin-right: 5%; }
  .placed-bets-info-wrapper .placed-bets-count {
    width: 39.516%;
    height: 100%;
    float: left;
    display: flex; }
    .placed-bets-info-wrapper .placed-bets-count > span {
      margin: auto;
      padding: 2px 20px;
      white-space: nowrap;
      border-radius: 13px;
      background-color: #212532;
      border: 1px solid #333743; }

.animation {
  transition-duration: 1s; }

/*Keno end*/
/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
.l-column-wrapper {
  width: 15.6771%;
  height: 100%;
  overflow: hidden;
  float: left; }
  .l-column-wrapper .finished-tickets {
    height: 80%;
    overflow-y: scroll; }
    .l-column-wrapper .finished-tickets .ticket-text {
      letter-spacing: 0.33px;
      background-color: #50626c;
      color: #e5e5e5;
      text-align: center;
      overflow: visible;
      font-size: 14px;
      padding: 2px; }

.l-column-mini {
  width: 2%; }

.l-top-box {
  width: 100%; }

.lottery-tab-wrapper {
  width: 100%;
  height: auto; }
  .lottery-tab-wrapper .lottery_tabs {
    width: 40%;
    height: 38px;
    display: inline-block;
    background: #2E323E;
    border-bottom: 1px solid #A71F67;
    color: #A5AABC;
    text-transform: uppercase;
    padding: 0 5%;
    white-space: nowrap; }
    .lottery-tab-wrapper .lottery_tabs > div {
      display: inline-block;
      line-height: 37px; }
      .lottery-tab-wrapper .lottery_tabs > div:second-child {
        color: #7B8191; }
    .lottery-tab-wrapper .lottery_tabs .text {
      letter-spacing: 0.36px;
      font-size: 0.7vw;
      width: 60%; }
    .lottery-tab-wrapper .lottery_tabs .count {
      letter-spacing: 0.3px;
      font-size: 0.55vw;
      text-align: right;
      width: 40%; }
      .lottery-tab-wrapper .lottery_tabs .count > span {
        border-radius: 50px;
        padding: 2px 5px;
        background: #00000033; }
    .lottery-tab-wrapper .lottery_tabs.active {
      color: #fff;
      background: #A71F67; }

.filter-view {
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  background: #212532;
  padding: 3px 8px 3px 5px; }
  .filter-view .r-tab {
    float: right;
    border: none;
    box-sizing: border-box;
    width: 30%;
    height: 24px;
    cursor: pointer; }
    .filter-view .r-tab .r-text {
      letter-spacing: 0.33px;
      border-radius: 2px;
      background-color: #1A7051;
      padding: 2%;
      font-size: 12px;
      text-align: center;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }

.filter-row {
  float: left;
  box-sizing: border-box;
  width: 70%;
  height: 24px;
  position: relative;
  display: flex; }
  .filter-row > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box; }
    .filter-row > ul > li {
      box-sizing: border-box;
      padding: 0 1px;
      cursor: pointer; }

.time-filter {
  box-sizing: border-box;
  height: 24px;
  position: relative;
  z-index: 271;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 0 30px;
  color: #AEAEAE;
  font-size: 12px;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
  margin: 0;
  /*>p{
        box-sizing: border-box;
        width: 100%;
        position: relative;
        padding: 0 30px;
        color: $DarkGray;
        font-size: 12px;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: uppercase;
        margin:0;*/ }
  .time-filter:before {
    left: 0;
    content: "\e912";
    box-sizing: border-box;
    width: 28px;
    height: 24px;
    position: absolute;
    top: 0;
    display: block;
    color: #7B8191;
    font: 19px/24px icomoon;
    text-align: center; }
  .time-filter .arrow-rotate-filter {
    right: 0;
    box-sizing: border-box;
    width: 28px;
    height: 24px;
    position: absolute;
    top: 0;
    display: block;
    color: #545B6B;
    text-align: center;
    font-style: normal; }
    .time-filter .arrow-rotate-filter:before {
      box-sizing: border-box;
      content: "\e90a";
      font: 30px/24px icomoon;
      display: block; }
  .time-filter.active {
    background: #363945; }
  .time-filter.active .arrow-rotate-filter:before {
    transform: rotateX(180deg); }
  .time-filter select {
    background-color: #212532;
    color: #7B8191;
    border: none;
    box-shadow: 0 0 0 1.2px #3F4553 inset; }

.second-row-filter {
  padding: 0 32px 0 0;
  box-sizing: border-box;
  float: left;
  width: 100%;
  position: relative;
  margin: 8px 0 0; }

.search-box {
  box-sizing: border-box;
  float: left;
  width: 100%;
  height: 30px;
  position: relative;
  z-index: 988; }
  .search-box:before {
    right: 12px;
    box-sizing: border-box;
    position: absolute;
    font: 24px/30px icomoon;
    content: "\e90e";
    color: #7B8191;
    display: block;
    top: 0;
    z-index: 5; }
  .search-box > form {
    box-sizing: border-box;
    float: left;
    width: 100%;
    height: 30px; }
  .search-box .search-results-container .games-list-mini-view {
    padding: 0; }
    .search-box .search-results-container .games-list-mini-view .competition-title {
      margin: 1px 0 0; }
  .search-box .block-search {
    box-sizing: border-box;
    float: left;
    width: 100%;
    margin: 0;
    background: none;
    border-radius: inherit;
    z-index: 77;
    position: static;
    padding: 0;
    min-width: 35px;
    height: 30px;
    text-align: right;
    max-width: 250px; }
    .search-box .block-search input[type=text] {
      padding: 0 30px 0 10px;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      color: #7B8191;
      background: none;
      width: 100%;
      height: 100%;
      border: none;
      box-shadow: 0 0 0 1.2px #333743 inset;
      font-size: 12px;
      line-height: 30px;
      color: #7B8191;
      z-index: 22;
      border-radius: inherit;
      transition: box-shadow 240ms;
      margin: 0; }
      .search-box .block-search input[type=text]:focus {
        box-sizing: border-box;
        box-shadow: 0 0 0 1.2px #545B6B inset;
        outline: none; }
      .search-box .block-search input[type=text]::placeholder {
        color: #7B8191; }

.closed-left-nav {
  right: 3px;
  box-sizing: border-box;
  width: 20px;
  height: 16px;
  position: absolute;
  top: 7px;
  color: #C2C5CD;
  z-index: 111;
  transform: translate3d(0, 0, 0);
  cursor: pointer; }
  .closed-left-nav:before {
    box-sizing: border-box;
    content: "\e909";
    color: #C2C5CD;
    font: 19px/16px icomoon; }

.l-full-menu-wrapper-box {
  box-sizing: border-box;
  text-align: left;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: auto;
  margin-top: 5px; }

.l-full-menu-wrapper {
  width: 100%;
  float: left; }

.left-nav-view, .favorites {
  box-sizing: border-box;
  float: left;
  width: 100%; }
  .left-nav-view > ul, .favorites > ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    float: left;
    width: 100%;
    list-style-type: none; }
    .left-nav-view > ul > li, .favorites > ul > li {
      list-style-type: none;
      box-sizing: border-box;
      width: 100%;
      margin: 0 0 1px;
      float: left; }
      .left-nav-view > ul > li .float-width, .favorites > ul > li .float-width {
        width: 100%;
        float: left;
        display: block;
        padding-bottom: 2px; }

/*Bootstrap css start*/
* {
  box-sizing: unset; }

.Collapsible .Collapsible__trigger.is-open .sports-item {
  background: #2e323e; }
  .Collapsible .Collapsible__trigger.is-open .sports-item > p {
    color: #C2C5CD; }

.Collapsible .Collapsible__trigger.is-open .region-item {
  background: #212532;
  color: #C2C5CD; }

.Collapsible .Collapsible__trigger.is-open .arrow-view:before {
  transform: rotateX(0deg);
  color: #C2C5CD;
  display: block; }

.Collapsible .Collapsible__trigger.is-open .show-more {
  background: #333743; }
  .Collapsible .Collapsible__trigger.is-open .show-more:before {
    transform: rotateX(180deg); }

.Collapsible .Collapsible__contentOuter {
  transition: height 100ms linear 0s !important;
  float: left;
  width: 100%; }

.Collapsible .Collapsible__contentInner .competition-title.active, .Collapsible .Collapsible__contentInner .fav-competition.active {
  pointer-events: none;
  background: #212532;
  color: #C2C5CD; }

/*Bootstrap css end*/
.sports-item {
  padding: 0 30px 0 37px;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: relative;
  background: #212532;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: background 240ms; }
  .sports-item:before {
    box-sizing: border-box;
    width: 37px;
    height: 100%;
    position: absolute;
    top: 0;
    font: 22px icomoon;
    line-height: inherit;
    color: #7B8191;
    padding: 0;
    text-align: center;
    left: 0;
    box-shadow: 3px 0 0 rgba(255, 255, 255, 0.15) inset; }
  .sports-item:hover {
    background: #2e323e;
    color: #C2C5CD; }
    .sports-item:hover > p {
      color: #C2C5CD; }
  .sports-item > p {
    width: 100%;
    font-size: 14px;
    line-height: inherit;
    color: #C2C5CD;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0; }

.nationalKeno:before {
  content: url("../static/images/92.png"); }

.quickLotteries:before {
  content: "\e912"; }

.favorites:before {
  content: "\e911"; }

.show-more {
  background: #282D3D; }
  .show-more:hover {
    background: #3F4553; }
  .show-more .sport-games-count {
    padding: 0 12px 0 0; }
  .show-more .arrow-view {
    display: none; }
  .show-more:before {
    content: "\e905";
    font-size: 24px;
    color: #C2C5CD; }

.sport-games-count {
  right: 0;
  padding: 0 37px 0 0;
  position: absolute;
  top: 0;
  color: #7B8191;
  font-size: 12px;
  line-height: inherit;
  transition: opacity 240ms; }

.arrow-view {
  right: 0;
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;
  font: 24px icomoon;
  line-height: inherit;
  text-align: center;
  transition: color 240ms; }
  .arrow-view:before {
    content: "\e90f";
    color: #C2C5CD;
    transform: rotateX(180deg);
    display: block; }
  .arrow-view:hover {
    color: #C2C5CD; }

.region-item-row {
  display: block;
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 0;
  overflow: hidden; }

.region-items-box {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 0; }

.full-region-info {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 0 0 1px; }
  .full-region-info:first-child {
    margin-top: 1px; }

.region-item {
  padding: 0 30px 0 36px;
  box-sizing: border-box;
  float: left;
  width: 100%;
  height: 30px;
  background: #282D3D;
  position: relative;
  cursor: pointer;
  transition: background 240ms, color 240ms;
  font-size: 14px;
  line-height: 30px;
  color: #7B8191;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
  .region-item .arrow-view:before {
    content: "\e90f";
    color: #C2C5CD;
    transform: rotateX(180deg);
    display: none; }
  .region-item:hover {
    background: #212532;
    color: #C2C5CD; }
    .region-item:hover .arrow-view:before {
      display: block; }

.flag-view {
  left: 10px;
  box-sizing: border-box;
  position: absolute;
  top: 10px; }

.flag-icon-c {
  width: 16px;
  height: 16px;
  image-rendering: pixelated;
  background-repeat: no-repeat; }

.games-list-nav {
  box-sizing: border-box;
  float: left;
  width: 100%; }

.region-item.active + .games-list-nav {
  display: block; }

.games-list-mini-view {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 0 0 1px;
  border: 2px solid #2E323E;
  border-top: none;
  padding: 3px 5px 5px; }

.competition-view {
  box-sizing: border-box;
  float: left;
  width: 100%;
  margin: 0; }

.competition-title, .fav-competition {
  box-sizing: border-box;
  float: left;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  height: 24px;
  background: #212532;
  margin: 5px 0 0;
  color: #7B8191;
  cursor: pointer;
  padding: 0 10px;
  position: relative;
  line-height: 22px; }
  .competition-title:hover, .fav-competition:hover {
    background: #2E323E;
    color: #C2C5CD; }
  .competition-title > p, .competition-title span, .fav-competition > p, .fav-competition span {
    font-size: 12px;
    line-height: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 5px 0 0;
    display: inline-block;
    line-height: inherit; }
  .competition-title > span, .fav-competition > span {
    margin-right: 0; }
  .competition-title .show-time, .fav-competition .show-time {
    right: 0;
    padding: 0 7px 0 0;
    position: absolute;
    top: 0;
    font-size: 12px;
    line-height: inherit;
    color: #545B6B;
    letter-spacing: 0.36px; }
    .competition-title .show-time .icon-close, .fav-competition .show-time .icon-close {
      font-size: 16px;
      line-height: 22px; }
      .competition-title .show-time .icon-close:hover, .fav-competition .show-time .icon-close:hover {
        transform: scale(1.2); }
        .competition-title .show-time .icon-close:hover:before, .fav-competition .show-time .icon-close:hover:before {
          color: #fff !important; }

.competition-title.active {
  pointer-events: none; }

.fav-competition.active {
  cursor: pointer; }

.competition-view:first-child > .competition-title {
  margin: 2px 0 0; }

.competition-view:first-child > .fav-competition {
  margin: 2px 0 0; }

/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
.r-column-wrapper {
  width: 15.364%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  float: left; }

/*tabs start */
.r-top-box .lottery-tab-wrapper {
  background: #2E323E;
  box-sizing: border-box;
  margin-top: 2px; }
  .r-top-box .lottery-tab-wrapper .round-text {
    width: auto;
    color: #7B8191;
    letter-spacing: 0.33px;
    font-size: 12px;
    text-align: center;
    line-height: 2.5;
    text-transform: uppercase; }
    .r-top-box .lottery-tab-wrapper .round-text span {
      cursor: pointer; }
  .r-top-box .lottery-tab-wrapper .lottery_tabs {
    border: none;
    box-sizing: border-box;
    width: 60%; }
    .r-top-box .lottery-tab-wrapper .lottery_tabs .text {
      width: auto;
      color: #c5c5c6;
      letter-spacing: 0.33px;
      font-size: 12px; }
    .r-top-box .lottery-tab-wrapper .lottery_tabs .count {
      text-align: left;
      width: auto;
      padding-left: 5px;
      font-size: 11px; }
      .r-top-box .lottery-tab-wrapper .lottery_tabs .count > span {
        background: transparent; }
    .r-top-box .lottery-tab-wrapper .lottery_tabs.active {
      color: #fff;
      background: unset; }

.r-top-box .r-tab {
  display: inline-block;
  height: 100%;
  padding: 0 3.5%;
  box-sizing: border-box;
  border: none;
  box-sizing: border-box;
  width: 40%;
  cursor: pointer; }
  .r-top-box .r-tab .r-text {
    text-overflow: ellipsis;
    text-transform: uppercase;
    letter-spacing: 0.33px;
    border-radius: 2px;
    background-color: #7B8191;
    padding: 2%;
    font-size: 12px;
    text-align: center;
    color: #fff;
    box-sizing: border-box;
    position: relative; }

.r-top-box .raunds-history-button {
  color: #fff;
  width: 100%;
  background-color: #7b8191; }

/*tabs end */
/*ticket start */
.r-full-menu-wrapper-box {
  height: calc(100% - 40px);
  overflow: hidden;
  margin-top: 2px; }
  .r-full-menu-wrapper-box .fav-numbers-info-box {
    width: 100%;
    height: 26.73vh;
    background: #212532;
    border-radius: 0 0 2px 2px;
    display: flex; }
    .r-full-menu-wrapper-box .fav-numbers-info-box > p {
      color: #7B8191;
      font-size: 11px;
      letter-spacing: 0.33px;
      margin: auto;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap; }
  .r-full-menu-wrapper-box .tickets-container {
    width: 100%;
    height: 100%;
    border-radius: 0 0 2px 2px;
    background: #212532;
    box-sizing: border-box;
    overflow-x: auto; }
    .r-full-menu-wrapper-box .tickets-container > div {
      overflow: hidden; }
    .r-full-menu-wrapper-box .tickets-container .ticket-text {
      letter-spacing: 0.33px;
      background-color: #2e323e;
      text-align: center;
      color: #f2f2f2;
      overflow: visible;
      font-size: 14px;
      padding: 2px; }
    .r-full-menu-wrapper-box .tickets-container .winning-tickets .ticket-text {
      background-color: #349572; }
    .r-full-menu-wrapper-box .tickets-container .waiting-tickets .ticket-text {
      background-color: #c4953e; }

.creating-ticket-wrapper, .created-tickets-wrapper {
  width: 100%;
  float: left;
  background: #212532;
  box-sizing: border-box;
  padding: 5px 5px 0 5px; }

.created-tickets-wrapper .ticket-item-wrapper {
  cursor: auto; }
  .created-tickets-wrapper .ticket-item-wrapper .ticket-item {
    padding: 0 0 3% 3.5%; }

.ticket-item-wrapper {
  height: auto;
  width: 100%;
  float: left;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(270deg, #333743 100%, #333743 0%);
  margin-bottom: 5px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative; }
  .ticket-item-wrapper .ticket-item, .ticket-item-wrapper .t-balls {
    width: 50%;
    box-sizing: border-box;
    height: 100%;
    float: left; }
  .ticket-item-wrapper .info-create-tickets {
    width: 100%;
    height: 20px;
    display: block;
    background-color: #0cae36;
    color: #ffff;
    font-size: 13px;
    text-align: start;
    text-transform: capitalize; }

.ticket-item {
  padding: 2.5% 0 3% 3.5%; }
  .ticket-item > div > span {
    display: block; }

.ticket {
  color: #7B8191;
  font-size: 12px; }

.country {
  color: #a6abba;
  font-size: 12px; }

.win, .loose {
  width: 99px;
  color: #29976F;
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 14px;
  margin-top: 5%; }

.loose {
  color: #B73C3C; }

.ticket-number-wrapper > span {
  vertical-align: middle; }

.ticket-number-wrapper .delete-created-ticket {
  cursor: pointer; }
  .ticket-number-wrapper .delete-created-ticket:before {
    font: 18px/0 icomoon;
    content: "\e902";
    color: #b5b8c0;
    margin-right: 3px; }

.delete-wrapper {
  position: relative;
  padding: 5px 0;
  display: table; }
  .delete-wrapper:before {
    font: 22px/30px icomoon;
    content: "\e903";
    color: #b73c3c;
    margin-left: -5px; }
  .delete-wrapper .delete {
    display: table-cell;
    vertical-align: middle;
    padding-left: 3px;
    color: #b73c3c;
    font-size: 11px; }

.bet {
  color: #AA8136;
  font-size: 12px;
  letter-spacing: 0.36px;
  line-height: 14px; }

.t-balls {
  padding: 2% 0; }
  .t-balls:before {
    content: "";
    position: absolute;
    height: 92%;
    width: 1px;
    background: #212532; }
  .t-balls .ball-items-container {
    box-sizing: border-box;
    padding-left: 6%;
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .t-balls .ball-items-container .ball-item-wrapper {
      width: 15%;
      height: 28%;
      margin: 1% 2%; }
      .t-balls .ball-items-container .ball-item-wrapper .ball-item {
        font-size: 11px; }
        .t-balls .ball-items-container .ball-item-wrapper .ball-item:hover {
          background: rgba(255, 255, 255, 0.9) !important;
          color: black !important;
          border: none !important; }

.created-tickets-wrapper .t-balls .ball-item {
  pointer-events: none !important; }

/*ticket end */
/*ticket-menu start */
.ticket-full-menu {
  width: 100%;
  padding: 0 2% 2%;
  box-sizing: border-box; }
  .ticket-full-menu > button {
    padding: 2.6% 0;
    border: none;
    outline: none; }

.Collapsible .Collapsible__trigger.is-open .ticket-item-wrapper {
  background: linear-gradient(180deg, #333743, rgba(51, 55, 67, 0));
  box-shadow: unset; }

.Collapsible .Collapsible__trigger.is-open .data-container > p {
  color: #fff; }

.percent-bar-wrapper {
  width: 100%;
  padding-bottom: 7%; }
  .percent-bar-wrapper > span {
    color: #C2C5CD;
    font-size: 11px;
    display: block;
    margin-bottom: 2.7%; }
  .percent-bar-wrapper .span {
    padding: 0 2px 9px 0;
    color: #C2C5CD;
    font-size: 13px;
    font-weight: bold; }
  .percent-bar-wrapper .ticket {
    padding: 2px 9px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    border-radius: 2px;
    background-color: #0cae36;
    margin-left: 5px; }
  .percent-bar-wrapper .span, .percent-bar-wrapper .ticket {
    display: inline-block; }
  .percent-bar-wrapper .rectangle {
    height: 10px;
    width: 100%;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5); }
  .percent-bar-wrapper .percent-bar {
    height: 10px;
    opacity: 0.8;
    border-radius: 2px;
    width: 0; }

.green-bar {
  background: linear-gradient(-145deg, #249E71, #1A7051); }

.yellow-bar {
  background: linear-gradient(-145deg, #F0B74D, #AA8136); }

.system-wrapper {
  border-top: 1px solid #0E141A;
  padding: 7% 0;
  display: flex;
  color: #7B8191;
  font-size: 11px; }
  .system-wrapper > div {
    width: 50%;
    margin: auto; }
  .system-wrapper .system-item {
    margin: auto;
    display: inline-block;
    width: 50%;
    text-align: right; }
    .system-wrapper .system-item > div {
      display: inline-flex;
      width: 49%;
      background-color: #2E323E;
      position: relative;
      padding: 1.3% 0; }
      .system-wrapper .system-item > div > select {
        width: 100%;
        outline: none;
        border: none;
        color: #7b8191;
        cursor: pointer;
        display: inline-flex;
        background-color: #2E323E;
        padding: 0 17%;
        -webkit-appearance: none; }
        .system-wrapper .system-item > div > select > option {
          border: 2px solid black;
          outline: none; }
    .system-wrapper .system-item .arrow {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      color: #7b8191;
      pointer-events: none; }
      .system-wrapper .system-item .arrow:after {
        font: 21px/26px icomoon;
        content: "\e90f";
        color: #7B8191;
        transform: rotateX(180deg);
        display: inline-block; }

.possible-winnings {
  padding: 6.7% 0;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #0E141A; }
  .possible-winnings .amount-container {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    margin-bottom: 4%; }
    .possible-winnings .amount-container > p {
      height: 100%;
      width: 14%;
      background-color: #AA8136;
      margin: 0;
      float: left;
      color: #fff;
      text-align: center;
      line-height: 30px;
      margin-right: 2%; }
    .possible-winnings .amount-container > div {
      padding: 0 2% 0 4%;
      color: #FFF;
      font-size: 11px;
      display: table;
      box-sizing: border-box;
      height: 100%;
      width: 84%;
      background-color: #2E323E;
      border: 1px solid #505767; }
      .possible-winnings .amount-container > div > input {
        outline: none;
        height: 100%;
        width: 100%;
        border: none;
        background-color: #2E323E;
        color: #FFF;
        display: table-cell; }
        .possible-winnings .amount-container > div > input::placeholder {
          color: #7B8191; }
      .possible-winnings .amount-container > div > span {
        display: table-cell;
        vertical-align: middle;
        color: #7B8191; }

.maxBet {
  cursor: pointer; }

.bet .data-container {
  height: 14px;
  color: #7B8191; }
  .bet .data-container:last-child {
    color: #fff; }
    .bet .data-container:last-child > p {
      color: #fff; }

.bet-err-text {
  margin: 0 0 5px;
  color: #c01d1d; }

.data-container {
  position: relative;
  padding: 1.5px 0;
  color: #fff;
  font-size: 11px; }
  .data-container:first-child {
    margin-bottom: 2%;
    cursor: pointer; }
    .data-container:first-child .arrow-view {
      font: 20px/20px icomoon; }
  .data-container .min-max-bet {
    font-weight: 700;
    color: #ffffffad; }
  .data-container > p {
    float: left;
    display: inline-block;
    margin: 0;
    color: #7B8191; }
  .data-container .arrow-view:before {
    color: #7B8191; }
  .data-container > div {
    display: inline-block;
    right: 0;
    position: absolute; }
    .data-container > div span:last-child {
      margin-left: 5px; }

/*ticket-menu end */
/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
/*Balls start*/
.balls-container {
  width: 50.61%;
  height: 100%;
  padding: 5px 0.76% 5px 0;
  box-sizing: border-box;
  background: #212532;
  border-radius: 2px; }

.ball-items-container {
  width: 78.354%;
  height: 100%;
  padding: 0 1.144%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  float: left; }
  .ball-items-container.no-bet {
    opacity: 0.5;
    pointer-events: none; }

.ball-items-wrapper {
  position: absolute;
  height: 100%;
  display: flex-wrap;
  display: flex;
  flex-wrap: wrap; }

.ball-item-wrapper {
  text-align: center;
  width: 6.615%;
  height: 11.89%;
  border-radius: 50%;
  margin: 0 1.078% 0.35% 0;
  padding: 0.195%;
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box; }
  .ball-item-wrapper:last-item {
    margin: 0; }

.ball-item {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  display: table;
  font-weight: 500;
  box-shadow: 0 0 0px 1.5px #fffc;
  cursor: pointer; }
  .ball-item > span {
    display: table-cell;
    vertical-align: middle; }
  .ball-item:hover {
    background: #49ac89 !important;
    color: #fff !important;
    border: 1.5px solid; }
  .ball-item.active {
    background: #1A7051 !important;
    color: #fff !important;
    box-shadow: 0 0 0 1.5px #1A7051 !important;
    border-color: white !important;
    border: 1.5px solid; }
  .ball-item.matched {
    background: #AA8136;
    color: #fff;
    border: #AA8136;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5); }
  .ball-item.disabled {
    pointer-events: none; }

.ball-item-dropped {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  display: table;
  font-weight: 500;
  box-shadow: 0 0 0px 1.5px #fffc;
  cursor: pointer; }
  .ball-item-dropped > span {
    display: table-cell;
    vertical-align: middle; }

/*Balls end*/
/*HotNumbers start*/
.hotNumbers-container {
  float: left;
  display: flex;
  flex-direction: column;
  width: 19%;
  height: 100%;
  border-radius: 5px;
  background-color: #333743;
  padding: 0.61%;
  box-sizing: border-box; }
  .hotNumbers-container .loading-wrapper {
    height: 63%; }
  .hotNumbers-container > button {
    background: #B73C3C;
    margin: 0;
    height: 28px; }
    .hotNumbers-container > button:hover {
      background: #df4e4e; }
  .hotNumbers-container .hotNumbers-wrapper {
    background: #212532;
    padding: 5.15%;
    box-sizing: border-box;
    border-radius: 5px;
    height: auto; }
    .hotNumbers-container .hotNumbers-wrapper > p {
      color: #C2C5CD;
      margin: 0 0 10% 0;
      font-size: 14px;
      text-align: center;
      line-height: 13px; }
    .hotNumbers-container .hotNumbers-wrapper .h-show-more {
      width: 100%;
      padding: 9% 0;
      position: relative;
      text-align: center;
      cursor: pointer; }
      .hotNumbers-container .hotNumbers-wrapper .h-show-more:before {
        content: "";
        background-color: #C2C5CD;
        height: 3px;
        width: 3px;
        position: absolute;
        border-radius: 50%;
        margin-left: 6px; }
      .hotNumbers-container .hotNumbers-wrapper .h-show-more:after {
        content: "";
        background-color: #C2C5CD;
        height: 3px;
        width: 3px;
        position: absolute;
        border-radius: 50%; }

.hotNumbers-items-wrapper {
  width: 100%;
  height: auto; }

.hotNumbers-items {
  display: flex;
  justify-content: space-between;
  margin-top: 2%; }

.hotNumber {
  box-sizing: border-box;
  width: 21px;
  height: 4.5%;
  opacity: 0.4;
  border: 1px solid #FFF;
  color: #fff;
  display: table;
  border-radius: 50%;
  line-height: 18px; }
  .hotNumber > span {
    font-size: 0.6vw;
    font-weight: 500;
    text-align: center;
    display: table-cell;
    vertical-align: middle; }

.progress-container {
  height: auto;
  width: 70%;
  border-radius: 2px;
  padding: 5% 0 0 0; }
  .progress-container .progress-bar-wrapper {
    height: 2px;
    background: rgba(0, 0, 0, 0.4);
    margin-bottom: 5%;
    margin: auto; }
    .progress-container .progress-bar-wrapper .progress-bar {
      height: 100%;
      opacity: 0.8;
      border-radius: 2px;
      background: linear-gradient(-145deg, #249E71, #1A7051); }
  .progress-container > span {
    opacity: 0.8;
    color: #7B8191;
    font-size: 10px;
    float: left; }
    .progress-container > span:last-child {
      float: right; }

.random-select-container {
  height: 23%;
  width: 100%;
  position: relative;
  border-radius: 2px;
  color: #fff; }
  .random-select-container > button {
    background: #b73c3c;
    margin: 0; }

.random-select-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #1A7051;
  color: #fff;
  margin: 4% 0; }
  .random-select-wrapper .random-select {
    height: 50%;
    width: 75%;
    margin: auto;
    display: flex;
    padding: 0;
    box-sizing: border-box; }
    .random-select-wrapper .random-select .decrement {
      cursor: pointer; }
      .random-select-wrapper .random-select .decrement:before {
        font-family: 'icomoon';
        content: "\e90b";
        font-size: 23px; }
    .random-select-wrapper .random-select .increment {
      cursor: pointer; }
      .random-select-wrapper .random-select .increment:before {
        font-family: 'icomoon';
        content: "\e90c";
        font-size: 23px; }
    .random-select-wrapper .random-select .selected-number {
      width: 40%;
      text-align: center; }
      .random-select-wrapper .random-select .selected-number > span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.42px;
        line-height: 32px; }
  .random-select-wrapper > button {
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0.36px;
    font-size: 12px;
    text-align: center;
    margin: 0;
    position: absolute;
    background: linear-gradient(0, #42a682, #1a7051);
    bottom: 0;
    padding: 0;
    height: 50%;
    overflow: hidden; }
    .random-select-wrapper > button:hover {
      background: linear-gradient(0, #75f3c7, #1a7051); }

/*HotNumbers end*/
/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
.popups-container {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0; }

.popup-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.4); }

@keyframes popup-bg {
  0% {
    background: rgba(13, 17, 33, 0); }
  100% {
    background: rgba(13, 17, 33, 0.84); } }

.modal-wrapper {
  border-radius: 4px;
  position: relative;
  background-color: #2E323E;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: auto; }
  .modal-wrapper .closed-btn {
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 20px;
    color: #7B8191; }
    .modal-wrapper .closed-btn:before {
      font-family: "icomoon";
      content: "\e901";
      font-size: 24px; }
    .modal-wrapper .closed-btn:hover {
      color: #ffffffb5; }

.ticket-m {
  width: 23%;
  height: 60%; }
  .ticket-m .modal-content-wrapper {
    width: 100%;
    overflow: hidden;
    height: 100%; }
  .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper {
    overflow: hidden;
    overflow-y: auto;
    height: 68%; }
  .ticket-m .modal-content-wrapper > button {
    margin: 6% auto; }

.result-m {
  width: 52.396%; }
  .result-m .modal-header-w {
    padding: 2%; }
    .result-m .modal-header-w > span {
      width: 22px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px; }
  .result-m .modal-content-wrapper > p {
    padding: 1.89%; }

.modal-header-w {
  width: 100%;
  padding: 4.7% 4%;
  border-radius: 2px 2px 0 0;
  background-color: #212532;
  display: table;
  box-sizing: border-box;
  border-bottom: 1px solid #0E141A; }
  .modal-header-w > h3 {
    color: #C2C5CD;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    margin: 0; }

.modal-content-wrapper {
  width: 100%;
  overflow: hidden;
  height: 90%;
  overflow-y: scroll; }
  .modal-content-wrapper > p {
    color: #7B8191;
    font-size: 14px;
    line-height: 16px;
    padding: 4%;
    margin: 0; }
  .modal-content-wrapper .hotNumbers-items-wrapper {
    overflow: hidden; }
    .modal-content-wrapper .hotNumbers-items-wrapper > ul {
      float: left;
      margin: 0;
      width: 25%;
      padding: 0 4%;
      position: relative; }
      .modal-content-wrapper .hotNumbers-items-wrapper > ul:nth-child(2):after {
        content: "";
        border-right: 1px solid #0E141A;
        opacity: 0.2;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 90%;
        display: block; }
      .modal-content-wrapper .hotNumbers-items-wrapper > ul:nth-child(2):before {
        content: "";
        border-left: 1px solid #0E141A;
        opacity: 0.2;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 90%;
        display: block; }
      .modal-content-wrapper .hotNumbers-items-wrapper > ul > li {
        list-style-type: none;
        text-align: center;
        box-sizing: border-box;
        padding: 9.37% 0; }
        .modal-content-wrapper .hotNumbers-items-wrapper > ul > li:first-child {
          opacity: 0.8;
          color: #7B8191;
          font-size: 12px; }
  .modal-content-wrapper .t-list-view {
    background: #2E323E;
    height: auto;
    padding: 0 8px 8px;
    box-sizing: border-box;
    float: left; }
    .modal-content-wrapper .t-list-view li {
      width: 2.5%; }
    .modal-content-wrapper .t-list-view .info-t-title {
      background: unset;
      opacity: 0.4;
      color: #7B8191;
      font-size: 12px;
      height: 38px; }
      .modal-content-wrapper .t-list-view .info-t-title > div.title {
        padding-left: 10px;
        float: left;
        width: 10%; }
      .modal-content-wrapper .t-list-view .info-t-title > div.width-li {
        width: 59%; }
    .modal-content-wrapper .t-list-view .single-list-row {
      overflow-y: auto;
      max-height: 452px; }
      .modal-content-wrapper .t-list-view .single-list-row .list > div {
        float: left;
        width: 30%; }
      .modal-content-wrapper .t-list-view .single-list-row .single-list {
        margin-bottom: 2px;
        border: none;
        position: relative;
        height: auto;
        float: left; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper {
          height: auto;
          width: 100%;
          float: left;
          position: relative;
          border-radius: unset;
          box-shadow: unset;
          background: #181B24;
          margin-bottom: unset;
          box-sizing: border-box;
          cursor: auto;
          padding-bottom: 1.5%; }
          .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper:first-child {
            padding-top: 1.5%; }
          .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .ticket-item {
            width: 40%;
            box-sizing: border-box;
            height: 100%;
            float: left;
            padding: 0; }
            .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .ticket-item > div {
              float: left;
              position: relative;
              padding: 0 15px 0 20px;
              width: 40%; }
              .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .ticket-item > div:nth-child(2) {
                border-left: 1px solid #3a3f52;
                width: 30%; }
              .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .ticket-item > div .currency, .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .ticket-item > div .country {
                color: #C2C5CD;
                font-size: 14px; }
          .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .t-balls {
            width: 60%;
            padding: 0; }
            .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .t-balls .ball-items-container {
              box-sizing: border-box;
              padding-left: 6%;
              display: flex;
              flex-wrap: nowrap;
              width: auto; }
              .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .t-balls .ball-items-container .ball-item-wrapper {
                width: 20px;
                height: 20px;
                margin: 0 3px; }
                .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .t-balls .ball-items-container .ball-item-wrapper .ball-item {
                  pointer-events: none !important; }
            .modal-content-wrapper .t-list-view .single-list-row .single-list .ticket-item-wrapper .t-balls:before {
              height: 35px;
              background: #3a3f52; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger.is-open > ul {
          cursor: auto; }
          .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger.is-open > ul > li {
            border-bottom: 1px solid #0E141A; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger > ul {
          height: 38px;
          width: 100%;
          display: table;
          table-layout: fixed;
          margin: 0;
          padding: 0 1.2%;
          box-sizing: border-box; }
          .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger > ul > li {
            display: table-cell;
            vertical-align: middle; }
            .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger > ul > li:last-child {
              width: 0.1%; }
            .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger > ul > li.width-li {
              width: 15%; }
              .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger > ul > li.width-li.li > div {
                margin: 10px; }
              .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger > ul > li.width-li .hotNumber {
                padding: 0;
                width: 22px;
                height: 22px;
                float: left;
                margin-right: 4.8px; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger .arrow-view {
          height: unset; }
          .modal-content-wrapper .t-list-view .single-list-row .single-list .Collapsible .Collapsible__trigger .arrow-view:before {
            color: #505767; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list:last-child {
          margin-bottom: 0; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list:hover {
          background-color: #181B24;
          color: #7B8191; }
        .modal-content-wrapper .t-list-view .single-list-row .single-list.active {
          background: #181B24;
          color: #7B8191; }
  .modal-content-wrapper > button {
    box-sizing: border-box;
    height: 35px;
    clear: both;
    margin: 8% auto;
    width: 160px;
    display: block;
    border: 1px solid #7B8191;
    background: unset;
    text-transform: uppercase;
    color: #C2C5CD;
    font-size: 14px;
    font-weight: 500;
    outline: none; }
    .modal-content-wrapper > button:hover {
      border: 1px solid #9d7f44;
      color: #9d7f44; }

/* ticket_modal start*/
/* ticket_modal end*/
/* result_modal start*/
/* result_modal end*/
/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
.alertContainer {
  height: 100%;
  width: 100%;
  z-index: 10000;
  display: flex;
  background: rgba(0, 0, 0, 0.4); }
  .alertContainer .alertDiv {
    background-color: #353a49;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin: auto;
    border: 1px solid #1d8a63;
    border-radius: 2px;
    width: 31%;
    height: auto;
    box-sizing: border-box;
    position: relative; }
    .alertContainer .alertDiv .header {
      background-color: #212532;
      height: 85px;
      text-align: left;
      text-indent: 8%;
      line-height: 85px;
      width: 100%;
      font-size: 20px;
      text-transform: uppercase;
      padding: 0;
      color: #e8e8ea; }
    .alertContainer .alertDiv .informationDiv {
      width: 100%;
      text-align: center;
      padding: 5% 0 5%;
      overflow: auto;
      box-sizing: border-box; }
      .alertContainer .alertDiv .informationDiv p {
        color: #e8e8ea;
        font-size: 22px;
        font-weight: 500;
        display: inline-block; }
      .alertContainer .alertDiv .informationDiv .bigFont {
        color: #fff;
        font-size: 32px;
        font-weight: bold;
        margin-top: 10px;
        display: block;
        vertical-align: middle;
        text-transform: uppercase; }
    .alertContainer .alertDiv .buttonsContainer {
      width: 100%;
      text-align: center;
      position: relative;
      margin: 0 0 5% 0; }
      .alertContainer .alertDiv .buttonsContainer > button {
        box-sizing: border-box;
        height: 35px;
        clear: both;
        margin: auto;
        width: 120px;
        padding: 0 20px;
        display: block;
        border: none;
        border-radius: 2px;
        background: #2bbb87;
        text-transform: uppercase;
        color: #e8e8ea;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 500;
        outline: none; }
        .alertContainer .alertDiv .buttonsContainer > button:hover {
          background: #2dc38d; }

/* overall design background */
/* live */
/* All, Quick lotteries, lotteries*/
/* search for keno , 1578 , Spain, UK Keno ... */
/* live- background */
/* All- background */
/* National keno - background */
/* Search for keno border color - background */
/* Uk keno 6/59 - background */
/* black borders */
/* Pre-match */
/* Pre-match - background */
/* green color shade - background */
/* All - green  - background */
/* to the blue - background */
/* to the blue - background */
/* lottery circle place - background */
/* lottery circle border color */
/* Round ID - background */
/* Clear button - background */
/* linear green short rows 100% - background (Salem color will be 0%)*/
/* linear yellow short rows 100% - background */
/* linear yellow short rows 0% - background */
/* linear coral short rows 0% - background */
/* linear coral short rows 100% - background */
/* popup parent - background */
/* lottery big circle - background / Artboard Copy 6*/
/* lottery small circle - background / Artboard Copy 6*/
/* Hot Numbers opacity color - background / Artboard Copy 6*/
.loading-wrapper {
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: flex;
  overflow-y: hidden; }

.ball-items-container .loading-wrapper {
  position: absolute !important; }

.progress-circular {
  width: 6%;
  height: 11.9%;
  margin: auto; }
  .progress-circular:before {
    content: '';
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 4px solid #a61f67;
    border-right: 4px solid transparent;
    animation: loadingSpinner 1200ms linear infinite; }

@keyframes loadingSpinner {
  100% {
    transform: rotate(360deg); } }

/*...................................my version*/
.mobile-header {
  display: block; }

@media only screen and (max-width: 812px) {
  .desktop-header {
    display: none; }
  .mobile-header {
    display: block; }
  .created-tickets-wrapper .ticket-text {
    letter-spacing: 0.33px;
    background-color: #2e323e;
    text-align: center;
    color: #7B8191;
    overflow: visible;
    font-size: 14px;
    padding: 2px; }
  .l-column-wrapper {
    width: 100%;
    order: 2; }
  .c-column-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 0.209%;
    order: 3; }
  .r-column-wrapper {
    height: 100%;
    width: 100%;
    order: -1;
    position: relative;
    display: flex;
    flex-direction: column; }
  .r-top-box .lottery-tab-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 2;
    border-bottom: 2px solid;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 41px;
    display: flex;
    width: 100%;
    margin-top: 0; }
  .lottery-tab-wrapper::-webkit-scrollbar {
    display: none; }
  .r-top-box .lottery-tab-wrapper .lottery_tabs,
  .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    display: inline-flex;
    justify-content: center;
    border-bottom: 1px transparent; }
    .r-top-box .lottery-tab-wrapper .lottery_tabs.active:after,
    .r-top-box .lottery-tab-wrapper .r-tab.active:after {
      position: absolute;
      content: "";
      bottom: 0;
      margin: 0 auto;
      animation: mymove 0.4s forwards;
      border-bottom: 1px solid #a71f67; }
  @keyframes mymove {
    from {
      width: 0; }
    to {
      width: 100%; } }
  .r-top-box .r-tab {
    padding: 0.71% 3.5%; }
  .r-top-box .r-tab .r-text {
    will-change: scroll-position;
    white-space: nowrap;
    background-color: unset;
    font-size: 13px; }
    .r-top-box .r-tab .r-text span {
      margin-left: 0; }
    .r-top-box .r-tab .r-text:before {
      content: ""; }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0; }
  .r-top-box .r-tab .r-text:before {
    content: ""; }
  /*..............................Competition header*/
  .l-full-menu-wrapper-box
.Collapsible
.Collapsible__contentInner
.competition-title.active {
    background-color: #ffffff1c; } }

@media only screen and (max-width: 375px) {
  .r-top-box .lottery-tab-wrapper .lottery_tabs,
  .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    width: auto; }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0;
    font-size: 12px; }
  /*............................competitions header*/
  .favorites:before {
    padding-left: 0; }
  .favorites p {
    padding-left: 0; }
  /*.competition-title > p,
  .competition-title span,
  .fav-competition > p,
  .fav-competition span {
     font-size: 12px;
     //font-size: 2.8vw;
  }*/
  .sports-item:before {
    font-size: 5vw; }
  .sports-item > p {
    font-size: 14px; } }

@media only screen and (max-width: 812px) {
  /*filter section start*/
  .filter-view {
    display: flex;
    align-items: center;
    padding-left: 52px; }
    .filter-view .filter-row {
      order: 1;
      width: 18.21%;
      height: 26px;
      min-width: 95px; }
      .filter-view .filter-row .time-filter {
        width: 100%;
        background-color: #4d515b;
        border-radius: 4px;
        padding: 0; }
        .filter-view .filter-row .time-filter:before {
          color: #ffff;
          font-size: 1.3em;
          background-color: #4d515b;
          z-index: 99999;
          height: 100%;
          width: 0;
          left: 3%; }
        .filter-view .filter-row .time-filter select {
          border: none;
          outline: none;
          box-shadow: none;
          background-color: #4d515b;
          width: 64%;
          height: 100%;
          right: 0;
          position: absolute;
          color: #ffff;
          padding: 0px 18%; }
          .filter-view .filter-row .time-filter select option {
            background-color: #4d515b;
            color: #ffff; }
    .filter-view .second-row-filter {
      order: 2;
      width: 38%;
      min-width: 150px;
      padding: 0;
      background-color: #2E323E;
      border-radius: 4px;
      margin: 0 8px;
      height: 26px; }
      .filter-view .second-row-filter .search-box {
        height: 24px; }
        .filter-view .second-row-filter .search-box .block-search {
          border-radius: 4px; }
        .filter-view .second-row-filter .search-box .search-results-container .games-list-mini-view {
          padding: 0; }
          .filter-view .second-row-filter .search-box .search-results-container .games-list-mini-view .competition-title {
            margin: 1px 0 0; }
        .filter-view .second-row-filter .search-box:before {
          color: #ffffffa8;
          height: 100%;
          right: 2%;
          top: -8%; }
    .filter-view .r-tab {
      width: 15%;
      order: 3; }
      .filter-view .r-tab .r-text {
        height: 20px; } }

@media only screen and (max-width: 375px) {
  /*............................search-filter header*/
  .filter-view {
    padding-left: 15px; }
    .filter-view .r-tab {
      margin-right: 21px; }
  .filter-view .filter-row {
    width: 39%; }
  .filter-view .second-row-filter {
    width: 61.37%; }
  .search-box > form {
    border-radius: 4px; }
  .filter-view .second-row-filter .search-box:before {
    color: #fffc;
    font-size: 5.2vw;
    top: -2px; }
  .filter-view .filter-row .time-filter select {
    color: #cccccc; }
  .filter-view .filter-row .time-filter:before {
    font-size: 1.2em; } }

@media only screen and (max-width: 812px) {
  .game-lobby-wrapper {
    flex-direction: column; }
  /*...........................game section*/
  .game-container {
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    background: #2e323e; }
  .game-container > div:first-child {
    padding-left: 12px; }
  .game-wrapper {
    padding: 0 0 0 22px;
    align-items: center;
    margin: 3% 0 1.5% 0; }
  .game-wrapper .ball-animation-container {
    width: 68px;
    margin: 0 0 0 1%; }
  .game-wrapper .ball-animation-container .ball-animation-wrapper {
    width: 100%;
    padding: 100% 0 0 0;
    position: relative;
    box-shadow: 0 0 8px 0 #ffffff30; }
    .game-wrapper .ball-animation-container .ball-animation-wrapper .drawn-number > span {
      font-size: 14px; }
  .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time {
    position: absolute;
    bottom: 0;
    margin-left: -2%; }
  .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time > span {
    font-size: 1.6vw; }
  .game-container .info, .game-container .round-start-time {
    float: left; }
  .game-container .info:before {
    font-size: 1.7vw; }
  .game-container .round-start-time:before {
    font-family: "icomoon";
    content: "\e912";
    font-size: 1.7vw; }
  .game-container .info > span, .game-container .round-start-time > span {
    font-size: 11px; }
  .game-wrapper .placed-bets-container {
    width: 71.38%;
    padding: 1.75%;
    position: relative;
    margin: 0; }
  .game-wrapper .placed-bets-container .ball-items-container {
    height: auto;
    margin: 0; }
  .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper {
    width: 26px;
    height: 26px;
    margin: 0 0.6% 0 0;
    padding-top: 4.382%;
    position: relative; }
    .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper:last-child {
      margin: 0; }
  /*................................Remaining time animation*/
  .game-wrapper .placed-bets-container .remaining-time {
    width: 9.4%;
    padding: 9.4% 0 0 0;
    position: relative; }
    .game-wrapper .placed-bets-container .remaining-time .spinner-circular {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0; }
  .game-wrapper .placed-bets-container .remaining-time .spinner-circular:last-child > span {
    font-size: 2.2vw; }
  .game-wrapper .placed-bets-container .remaining-time .one:before {
    border: 3px solid #7b81914d; }
  .game-wrapper .placed-bets-container .remaining-time .two:before {
    border-top: 3px solid #C2C5CD;
    border-right: 3px solid transparent; }
  /*...........................balls section*/
  .balls-container {
    width: 100%;
    background: #2e323e;
    padding-left: 10px;
    justify-content: space-between;
    display: flex; }
  .ball-item-wrapper {
    width: 25px;
    height: 25px;
    position: relative;
    margin: 0 0.7% 1% 0;
    opacity: 0.8;
    padding: 5.45% 0 0 0; }
  .ball-item {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    font-size: 11px; }
  .ball-item-dropped {
    bottom: 0;
    position: absolute; }
  .ball-item-dropped > span {
    font-size: 11px; }
  .game-wrapper .ball-animation-container .ball-animation-wrapper .ball-animation {
    position: absolute;
    bottom: 0; }
  /*...................................Placed bets section*/
  .placed-bets-info-wrapper:before {
    content: "";
    position: absolute;
    border: 3px solid #212532;
    width: 100%; }
  .placed-bets-info-wrapper .placed-bets-info {
    padding: 4% 0 2% 10px; }
  .placed-bets-info-wrapper .placed-bets-count {
    display: none; }
  .placed-bets-info-wrapper .placed-bets-info > h1 {
    font-size: 12px; }
  .progress-container {
    display: none; }
  .placed-bets-info-wrapper span {
    color: #7b8191;
    font-size: 11px;
    font-weight: 500;
    display: block; }
  /*.................................Hot Numbers section*/
  .hotNumbers-container {
    background-color: transparent;
    width: 24%;
    padding: 0;
    margin-right: 20px; }
  .hotNumbers-container .hotNumbers-wrapper {
    width: 100%;
    background-color: #333743; }
  .random-select-container {
    height: auto;
    margin: 4% 0 4% 0;
    background-color: transparent; }
  .random-select-wrapper {
    height: auto;
    background: transparent; }
  .hotNumbers-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  .hotNumbers-items {
    margin: 4% 4%;
    width: 23.55%;
    height: 23.55%;
    padding: 23.55% 0 0 0;
    position: relative; }
  .hotNumber {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    line-height: 0; }
  .hotNumber > span {
    font-size: 1.6vw;
    font-weight: unset; }
  .hotNumbers-container .hotNumbers-wrapper > p {
    font-size: 2.6vw; }
  .random-select-container > button {
    position: relative;
    height: auto;
    padding: 7.1% 0;
    font-size: 1.6vw; }
  .random-select-wrapper > button {
    position: relative;
    background: #1a7051;
    height: auto;
    padding: 7.1% 0;
    font-size: 1.6vw; }
  .random-select-wrapper .random-select .decrement:before {
    font-size: 3.2vw; }
  .random-select-wrapper .random-select .increment:before {
    font-size: 3.2vw; }
  .random-select-wrapper .random-select {
    width: 100%;
    justify-content: space-evenly;
    align-items: center; }
  .hotNumbers-container .hotNumbers-wrapper .h-show-more:after {
    margin-left: -5px; }
  .random-select-wrapper .random-select .selected-number {
    width: auto; }
  .random-select-wrapper .random-select .selected-number > span {
    font-size: 2vw; }
  .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .flag-icon-c {
    width: 22px; }
  .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .round-id {
    margin-right: 3%; }
  .placed-bets-info-wrapper span.roundNumber {
    margin-left: -20%; } }

@media only screen and (max-width: 580px) {
  .placed-bets-info-wrapper .placed-bets-info > h1 {
    font-size: 10px; }
  .placed-bets-info-wrapper span {
    font-size: 1.8vw; } }

@media only screen and (max-width: 375px) {
  .game-container .info, .game-container .round-start-time {
    float: none;
    font-size: 2.8vw; }
  .game-wrapper .ball-animation-container .ball-animation-wrapper .round-start-time > span {
    font-size: 3vw; }
  .game-container .info:before {
    font-size: 3.7vw; }
  .game-container .round-start-time:before {
    font-size: 3.7vw; }
  .game-container > div:first-child {
    padding: 1.5% 0 1.5% 15px; }
  .game-container .round-start-time {
    margin-left: 0; }
  .game-wrapper {
    width: 95%;
    padding: 0 0 0 10px;
    margin: 0% 0 1.5% 0; }
  .game-wrapper .placed-bets-container {
    width: 76.6%;
    padding: 0.75%;
    margin-left: 0; }
  .game-wrapper .placed-bets-container .ball-items-container {
    margin: 0 0 0 2%; }
  .game-wrapper .placed-bets-container .ball-items-container .ball-item-wrapper {
    width: 8.615%;
    margin: 0 1.1% 1% 0px;
    padding-top: 8.615%;
    /* .ball-item {
            height: 28px;
        }*/ }
  .game-wrapper .ball-animation-container {
    width: 16.45%; }
  .placed-bets-info-wrapper .placed-bets-info {
    padding: 2% 0 2% 15px; }
  .balls-container {
    padding: 0 16px 0 0;
    flex-direction: column;
    width: 100%; }
  .ball-items-container {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box; }
  .placed-bets-info-wrapper span {
    font-size: 11px; }
  .ball-item-wrapper {
    width: 9%;
    padding: 9% 0 0 0;
    margin: 0 1% 1.4% 0; }
  .placed-bets-info-wrapper .placed-bets-info .round-id-wrapper .flag-icon-c {
    width: 30px;
    height: 18px; }
  /*................................Remaining time animation*/
  .game-wrapper .placed-bets-container .remaining-time {
    width: 18.31%;
    padding: 18.31% 0 0 0; }
  .game-wrapper .placed-bets-container .remaining-time {
    width: 18.31%;
    padding: 18.31% 0 0 0;
    position: relative;
    margin: 1% 0 1% 5%; }
  .hotNumbers-container {
    width: 100%;
    padding: 0;
    margin-right: 0;
    border-top: 6px solid #212532;
    box-sizing: border-box;
    border-radius: 0;
    position: relative; }
  .hotNumbers-container .loading-wrapper .progress-circular {
    height: 25px; }
  .hotNumbers-container .hotNumbers-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3% 10px;
    align-items: center; }
  .hotNumbers-container .hotNumbers-wrapper > p {
    font-size: 2.8vw;
    margin: 0;
    width: 47%;
    text-align: left; }
  .hotNumbers-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 52%; }
  .hotNumbers-items {
    margin: 0 5% 0 0;
    width: 14.25%;
    padding: 14.25% 0 0 0;
    position: relative; }
  .hotNumber > span {
    font-size: 0.7em;
    font-weight: unset; }
  .hotNumbers-container .hotNumbers-wrapper .h-show-more {
    padding: 0% 0;
    width: 10%; }
  .hotNumbers-container > button {
    padding: 2.7% 0;
    height: auto;
    font-size: 3vw;
    width: 32%;
    position: absolute;
    top: 54%;
    left: 10px; }
  .hotNumbers-container .progress-circular:before {
    width: 50%; }
  /*.................Random select*/
  .random-select-container {
    height: auto;
    margin: 0 0 4% 0;
    background-color: transparent;
    display: flex;
    padding: 0; }
    .random-select-container > button {
      position: relative;
      height: auto;
      padding: 3.1% 0;
      font-size: 3vw;
      width: 32.5%;
      order: 1;
      margin: 0 auto 0 10px; }
  .random-select-wrapper {
    height: auto;
    margin: 0 0 4% 0;
    background-color: transparent;
    display: flex;
    width: 62%;
    margin: 0 10px 0 auto;
    order: 2; }
  .random-select-wrapper > button {
    position: relative;
    padding: 4.35% 0;
    font-size: 1.6vw;
    height: auto;
    font-size: 3vw; }
  .random-select-wrapper .random-select {
    width: 100%;
    justify-content: space-evenly;
    align-items: center; }
  .random-select-wrapper .random-select .increment:before {
    font-size: 7.2vw; }
  .random-select-wrapper .random-select .decrement:before {
    font-size: 7.2vw; }
  .random-select-wrapper .random-select .selected-number > span {
    font-size: 4vw; } }

@media only screen and (max-width: 812px) {
  body {
    background-color: #212532; }
  #root {
    max-width: 812px;
    min-width: 375px;
    overflow-y: unset;
    height: auto; }
  .app {
    display: flex;
    flex-direction: column;
    height: auto; }
  .app > div {
    width: 100%; }
  .modal-wrapper {
    background-color: #1e2638; }
  .popups-container {
    height: 100%;
    background: rgba(0, 0, 0, 0.4); }
    .popups-container .alertContainer {
      height: 100vh;
      background: transparent; }
      .popups-container .alertContainer .alertDiv {
        border: none;
        width: 40%;
        height: auto; }
        .popups-container .alertContainer .alertDiv .header {
          height: auto;
          line-height: unset;
          font-size: 2.2vw;
          padding: 15px 0; }
        .popups-container .alertContainer .alertDiv .informationDiv p {
          font-size: 2.5vw; }
  .mobile-bottom {
    height: auto;
    width: 100%;
    background: #2e323e;
    position: relative;
    order: 4;
    padding: 2% 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    cursor: pointer;
    bottom: 0;
    /*  &:before {
        content: "";
        position: absolute;
        width: 5.5%;
        height: 0px;
        opacity: 0.3;
        border: solid 3px #ffffff;
        border-radius: 8px;
        top: 7px;
      }*/ }
    .mobile-bottom.no-bet {
      opacity: 0.5;
      pointer-events: none; }
    .mobile-bottom button {
      outline: none;
      color: #FFFFFF;
      border: none !important;
      width: 90%;
      background-color: #1a7051; }
    .mobile-bottom .ticket-item-wrapper .percent-bar-wrapper {
      padding-bottom: 1%; }
    .mobile-bottom .ticket-item-wrapper .t-balls {
      width: auto;
      float: none; }
    .mobile-bottom .ticket-item-wrapper .ticket-item > div {
      padding-top: 7px;
      float: left; }
    .mobile-bottom .ticket-item-wrapper .ticket-item {
      padding-left: 10px;
      height: 39px;
      width: 100%;
      padding: 0; }
      .mobile-bottom .ticket-item-wrapper .ticket-item .country {
        float: left; }
      .mobile-bottom .ticket-item-wrapper .ticket-item .delete-wrapper {
        float: right;
        padding: 6px 21px 0px; }
    .mobile-bottom .ticket-item-wrapper .ball-items-container {
      padding-top: 2px;
      padding-left: 6px;
      width: 100%;
      margin: auto;
      border-radius: 19px;
      box-sizing: border-box; }
      .mobile-bottom .ticket-item-wrapper .ball-items-container .ball-item-wrapper {
        padding-top: 2px;
        width: 25px;
        height: 25px;
        margin: 1% 0.5%; }
    .mobile-bottom .ticket-number-wrapper {
      float: left; }
      .mobile-bottom .ticket-number-wrapper .ticket {
        float: left; }
    .mobile-bottom .ticket-full-menu > button {
      padding: 1.5% 0; }
    .mobile-bottom .ticket-full-menu .button-view {
      width: 100%;
      margin: 5px 0 0 0; }
    .mobile-bottom .ticket-full-menu .system-wrapper {
      width: 95%;
      float: left;
      text-align: start;
      border-bottom: 1px solid #464750;
      border-top: 1px solid #464750;
      padding: 1% 0; }
      .mobile-bottom .ticket-full-menu .system-wrapper .system-item div {
        width: 67%;
        border-radius: 4px;
        height: 26px; }
      .mobile-bottom .ticket-full-menu .system-wrapper .arrow {
        right: 4px; }
    .mobile-bottom .ticket-full-menu .amount-container {
      margin-bottom: 2%;
      border: none; }
      .mobile-bottom .ticket-full-menu .amount-container .maxBet {
        width: 8%;
        margin-right: 0; }
      .mobile-bottom .ticket-full-menu .amount-container div {
        width: 92%; }
    .mobile-bottom .possible-winnings {
      border-top: none;
      width: 100%;
      float: right;
      padding: 0; }
      .mobile-bottom .possible-winnings .button-view {
        margin: 0; }
      .mobile-bottom .possible-winnings .amount-container .maxBet {
        width: 15%; }
      .mobile-bottom .possible-winnings .amount-container > div {
        width: 80%; }
  .bottom-none {
    display: none; }
  /*.................Loading */
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div.loading-wrapper {
    width: 100%; }
    .c-column-wrapper #mobile-lobby-wrapper .t-list-view div.loading-wrapper .progress-circular {
      width: 30px; }
  .region-item-row .region-item {
    font-size: 14px; } }

@media only screen and (max-width: 620px) {
  .popups-container .alertContainer .alertDiv {
    width: 50%; }
    .popups-container .alertContainer .alertDiv .header {
      font-size: 2.5vw;
      padding: 15px 0; }
    .popups-container .alertContainer .alertDiv .informationDiv p {
      font-size: 3vw; }
    .popups-container .alertContainer .alertDiv .alertContainer .alertDiv .buttonsContainer > button {
      height: auto;
      width: 37%;
      padding: 2.2% 0;
      font-size: 2.4vw; } }

@media only screen and (max-width: 375px) {
  .popups-container .alertContainer .alertDiv .header {
    font-size: 3vw;
    padding: 10px 0; }
  .popups-container .alertContainer .alertDiv .informationDiv p {
    font-size: 3.5vw; }
  .mobile-bottom:before {
    width: 11%;
    margin-left: -5%; } }

@media only screen and (max-width: 812px) {
  .favorite-view.left-nav-view .favorites ul li {
    margin-left: 1.5px; }
  .left-nav-view > ul, .favorites > ul {
    display: flex; }
  .favorites p {
    padding-left: 5px; }
  .favorites:before {
    padding-left: 3px; }
  .sports-item:before {
    box-shadow: none; }
  .l-full-menu-wrapper-box {
    margin-top: 0; }
    .l-full-menu-wrapper-box .competition-title {
      background-color: #2e323e;
      height: 33px;
      padding-top: 5px; }
    .l-full-menu-wrapper-box ul li {
      margin-left: 1.5px; }
    .l-full-menu-wrapper-box .Collapsible .Collapsible__contentInner .competition-title {
      color: #fff;
      margin: 0; }
      .l-full-menu-wrapper-box .Collapsible .Collapsible__contentInner .competition-title.active {
        background-color: #4d515b; }
    .l-full-menu-wrapper-box .sports-item {
      background-color: #2e323e;
      height: 35px; }
      .l-full-menu-wrapper-box .sports-item.before {
        color: #ffff; }
    .l-full-menu-wrapper-box .games-list-mini-view {
      padding: 0;
      border: none; }
    .l-full-menu-wrapper-box .arrow-view {
      padding-right: 25px; }
  #web-game-container {
    display: none; }
  #mobile-game-container {
    display: block; }
  .c-column-wrapper {
    flex-direction: column-reverse;
    display: flex; }
    .c-column-wrapper .game-lobby-wrapper {
      float: left;
      flex-direction: column; }
      .c-column-wrapper .game-lobby-wrapper .balls-container .ball-item-wrapper {
        width: 28px; }
    .c-column-wrapper .lobby-wrapper {
      height: 220px; }
    .c-column-wrapper #lobby-web {
      display: none; }
    .c-column-wrapper #lobby-mobile {
      display: block; }
    .c-column-wrapper #mobile-lobby-wrapper {
      display: block;
      height: 180px; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view {
        font-size: 14px; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view div {
        height: 35px; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view div select {
          -webkit-appearance: none;
          box-sizing: border-box;
          color: #fff;
          border: none;
          background: #343844;
          padding: 0 30px 0 11px;
          width: 100%;
          height: 35px;
          position: relative;
          cursor: pointer;
          border: none;
          outline: none;
          /* option {
             border: none;
             outline: none;
           }*/
          background-image: linear-gradient(45deg, transparent 50%, #fff 50%), linear-gradient(135deg, #fff 50%, transparent 50%), linear-gradient(to right, #343844, #343844);
          background-position: calc(100% - 40px) calc(1em + 2px), calc(100% - 35px) calc(1em + 2px), 100% 0;
          background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
          background-repeat: no-repeat; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul {
          width: 100%;
          display: block;
          height: 35px; }
          .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li {
            display: block;
            width: 100%; }
            .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li div {
              width: 100%; }
              .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li div .favorite-view {
                padding-right: 27px;
                width: auto;
                border-right: 1px solid rgba(0, 0, 0, 0.25);
                border-left: none; }
              .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li div p {
                padding-left: 0; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view div .info-c-type {
          font-size: 14px;
          background-color: #2e323e;
          margin: 0;
          border-bottom: none; }
          .c-column-wrapper #mobile-lobby-wrapper .t-list-view div .info-c-type .favorite-view {
            background-color: #2e323e; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li div .flag-view {
        margin-left: 12px; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view .mobile-rounds {
        will-change: scroll-position;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        height: 45px;
        margin-top: 7px;
        display: flex; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view .mobile-rounds .rounds-elements {
          cursor: pointer;
          float: left;
          width: auto;
          padding: 2px 30px 2px;
          display: block;
          border-radius: 4px;
          border: solid 1px #ffffff;
          height: 22px;
          text-align: center;
          margin-left: 5px; }
          .c-column-wrapper #mobile-lobby-wrapper .t-list-view .mobile-rounds .rounds-elements:first-child {
            margin-left: 10px; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view .active {
        background-color: #fff;
        color: #343844; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item {
        will-change: scroll-position;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        height: 58px;
        display: contents; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .rounds-item-elements {
          width: auto; }
          .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .rounds-item-elements:first-child {
            margin-left: 10px; }
          .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .rounds-item-elements div {
            width: 82px; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list {
          will-change: scroll-position;
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          height: 58px;
          display: flex;
          font-size: 13px;
          text-align: center; }
          .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements {
            text-align: left; }
            .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements span {
              width: 82px;
              display: block; }
              .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements span:nth-child(2) {
                color: #C2C5CD; }
              .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements span:first-child {
                padding: 0 0 4px; }
            .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements:first-child {
              text-align: left; }
        .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .show-round {
          visibility: visible; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view .all-rounds-list::-webkit-scrollbar {
        display: none; }
      .c-column-wrapper #mobile-lobby-wrapper .t-list-view .mobile-rounds::-webkit-scrollbar {
        display: none; }
      .c-column-wrapper #mobile-lobby-wrapper .favorite-view {
        width: auto; }
      .c-column-wrapper #mobile-lobby-wrapper .favorite-view {
        width: auto; }
    .c-column-wrapper #web-lobby-wrapper {
      display: none; }
  .mobile-show-count {
    display: block;
    right: 30px;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    line-height: inherit;
    text-align: center;
    transition: color 240ms;
    color: #ccc;
    font-size: 9pt; } }

@media only screen and (max-width: 375px) {
  .l-full-menu-wrapper {
    display: block; }
  .left-nav-view, .favorites {
    width: 100%; }
  .l-full-menu-wrapper-box {
    height: auto; }
  .favorite-view.left-nav-view {
    width: 100%; }
  .left-nav-view > ul, .favorites > ul {
    display: block; } }

@media only screen and (max-width: 375px) {
  .r-top-box .lottery-tab-wrapper .lottery_tabs, .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    width: auto; }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0;
    font-size: 2.6vw; }
  /*............................competitions header*/
  .favorites:before {
    padding-left: 0; }
  .favorites p {
    padding-left: 0; }
  .competition-title > p, .competition-title span, .fav-competition > p, .fav-competition span {
    font-size: 12px; }
  .sports-item:before {
    font-size: 5vw; }
  .sports-item > p {
    font-size: 2.8vw; }
  .l-full-menu-wrapper-box ul li {
    margin-left: 1.5px;
    margin: 0; }
  .l-full-menu-wrapper-box .left-nav-view > ul > li .float-width, .l-full-menu-wrapper-box .favorites > ul > li .float-width {
    margin-left: 0; }
  .l-full-menu-wrapper-box .arrow-view {
    padding-right: 18px; }
  .info-c-type > li .type-item .favorite-view.active:before {
    display: none; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li div .flag-view {
    margin-left: 10px; }
  .t-list-view {
    width: 100%;
    font-size: 3.2vw;
    display: flex;
    flex-direction: column; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div {
    width: 100%; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div:nth-child(2) {
    background: #ffffff1c;
    order: 0; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div {
    width: 100%;
    order: 1;
    height: 26px; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div select {
    font-size: 12px;
    height: 26px;
    padding-left: 15px; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div .info-c-type {
    padding: 0; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .mobile-rounds .rounds-elements:first-child {
    margin-left: 15px; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .rounds-item-elements:first-child {
    margin-left: 15px; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list {
    padding-left: 15px; }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements {
    background: unset; } }

@media only screen and (max-width: 812px) {
  .r-full-menu-wrapper-box {
    height: auto;
    overflow: hidden; }
    .r-full-menu-wrapper-box .fav-numbers-info-box {
      display: none; }
  .finished-t-mobile {
    border-top: 6px solid #212532; }
  .tickets-container {
    border-radius: 0; }
  .tickets-container .created-tickets-wrapper {
    overflow-y: auto; }
  .tickets-container .waiting-tickets {
    max-height: 222px; }
    .tickets-container .waiting-tickets .delete-created-ticket {
      position: absolute;
      right: 0;
      margin-top: 3px; }
      .tickets-container .waiting-tickets .delete-created-ticket:before {
        content: "\e901";
        color: #ffffff;
        font-size: 22px; }
  .tickets-container .finished-tickets {
    max-height: 136px; }
  .tickets-container .ticket-text {
    display: none; }
  .created-tickets-wrapper {
    padding: 0; }
    .created-tickets-wrapper .ticket-item-wrapper {
      border-radius: 0;
      box-shadow: unset;
      margin-bottom: 2px;
      padding: 15px 0 10px; }
      .created-tickets-wrapper .ticket-item-wrapper:last-child {
        margin-bottom: 0; }
    .created-tickets-wrapper .ticket-item-wrapper .ticket-item {
      display: flex;
      position: relative;
      margin-bottom: 5%;
      width: 100%; }
      .created-tickets-wrapper .ticket-item-wrapper .ticket-item div span {
        width: 100%; }
    .created-tickets-wrapper .ticket-item-wrapper .ticket-item, .created-tickets-wrapper .ticket-item-wrapper .t-balls {
      height: 57px;
      padding: 0 0 0 52px;
      flex-wrap: wrap;
      width: 100%; }
    .created-tickets-wrapper .ticket-item-wrapper .ticket-item > div {
      display: flex;
      align-items: center; }
      .created-tickets-wrapper .ticket-item-wrapper .ticket-item > div:first-child {
        flex-grow: 1;
        width: 79%; }
      .created-tickets-wrapper .ticket-item-wrapper .ticket-item > div:last-child {
        flex-grow: 1; }
    .created-tickets-wrapper .ticket-number-wrapper {
      margin-right: 5%; }
      .created-tickets-wrapper .ticket-number-wrapper .ticket {
        font-size: 11px;
        position: relative;
        display: inline-block;
        padding-right: 4px; }
        .created-tickets-wrapper .ticket-number-wrapper .ticket:before {
          content: "";
          border-right: 1px solid #ffffff33;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%; }
      .created-tickets-wrapper .ticket-number-wrapper .country {
        font-size: 11px;
        position: relative;
        display: inline-block; }
        .created-tickets-wrapper .ticket-number-wrapper .country:before {
          content: "";
          border-right: 1px solid #ffffff33;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%; }
    .created-tickets-wrapper .ticket-item > div > span {
      margin: 0 5% 0 0;
      display: inline-block; }
    .created-tickets-wrapper .t-balls:before {
      display: none; }
    .created-tickets-wrapper .t-balls .ball-items-container {
      padding: 0; }
    .created-tickets-wrapper .t-balls .ball-items-container .ball-item-wrapper {
      width: 3.22%;
      padding: 3.22% 0 0 0;
      margin: 0 4.5% 0 0%;
      position: relative; }
    .created-tickets-wrapper .t-balls .ball-items-container .ball-item-wrapper .ball-item {
      font-size: 11px;
      width: 23px;
      height: 23px;
      bottom: 0;
      position: absolute; }
    .created-tickets-wrapper .ball-item > span {
      color: #1e2638;
      font-size: 11px; } }

@media only screen and (max-width: 620px) {
  .created-tickets-wrapper .ball-item > span {
    font-size: 9px; }
  .created-tickets-wrapper .ticket-item-wrapper {
    margin-right: 5%; }
    .created-tickets-wrapper .ticket-item-wrapper .ticket, .created-tickets-wrapper .ticket-item-wrapper .country, .created-tickets-wrapper .ticket-item-wrapper .bet, .created-tickets-wrapper .ticket-item-wrapper .loose {
      font-size: 11px; } }

@media only screen and (max-width: 375px) {
  .r-full-menu-wrapper-box .created-tickets-wrapper .ticket-item-wrapper .ticket-item, .r-full-menu-wrapper-box .created-tickets-wrapper .ticket-item-wrapper .t-balls {
    height: 53px;
    padding: 0 15px;
    flex-wrap: nowrap; }
  .r-full-menu-wrapper-box .created-tickets-wrapper .ticket-item-wrapper .t-balls {
    flex-wrap: wrap; } }

@media only screen and (max-width: 812px) {
  .rounds-result-mobile {
    width: 100%;
    height: auto;
    /*.............................Result Filter section start*/
    /*.............................Result Filter section end*/ }
    .rounds-result-mobile .rounds-result-filter {
      width: 100%;
      box-sizing: border-box;
      padding: 7px 13px 10px 12px;
      float: left; }
      .rounds-result-mobile .rounds-result-filter .trigger-content {
        padding: 1.64% 1.5%;
        position: relative;
        background-color: #ffffff1a;
        border-radius: 4px;
        height: auto;
        line-height: unset;
        width: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        transition: background 240ms;
        margin-bottom: 1px; }
        .rounds-result-mobile .rounds-result-filter .trigger-content p {
          font-size: 14px;
          color: #c2c5cd;
          margin: 0; }
        .rounds-result-mobile .rounds-result-filter .trigger-content .arrow-view {
          width: 6%;
          font: 3.5vw icomoon;
          line-height: inherit;
          right: 0;
          height: 100%;
          position: absolute;
          top: 0;
          text-align: center;
          transition: color 240ms; }
      .rounds-result-mobile .rounds-result-filter .filter-container {
        width: auto;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #ffffff26;
        padding: 10px; }
      .rounds-result-mobile .rounds-result-filter .filter-content-wrapper {
        display: flex; }
      .rounds-result-mobile .rounds-result-filter .filter-scrollable-section {
        width: 40%;
        margin: 0 15px 14px 0;
        border-radius: 4px;
        background-color: #64666c; }
        .rounds-result-mobile .rounds-result-filter .filter-scrollable-section .trigger-content {
          background-color: #64666c;
          padding: 0 4%; }
          .rounds-result-mobile .rounds-result-filter .filter-scrollable-section .trigger-content span {
            color: #ffffff66;
            font-size: 12px; }
          .rounds-result-mobile .rounds-result-filter .filter-scrollable-section .trigger-content p {
            color: #eaeaea;
            font-size: 14px; }
          .rounds-result-mobile .rounds-result-filter .filter-scrollable-section .trigger-content .arrow-view {
            padding-right: 3%; }
      .rounds-result-mobile .rounds-result-filter .filter-items {
        display: flex;
        width: 50%; }
        .rounds-result-mobile .rounds-result-filter .filter-items .filter-from,
        .rounds-result-mobile .rounds-result-filter .filter-items .filter-to {
          width: 49%;
          height: 52px;
          margin: 0 0 14px 5px;
          padding: 0 4%;
          border-radius: 4px;
          background-color: #64666c;
          position: relative; }
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-from span,
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-to span {
            color: #ffffff66;
            font-size: 12px; }
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-from p,
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-to p {
            color: #eaeaea;
            font-size: 14px; }
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-from .arrow-view,
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-to .arrow-view {
            top: -4px;
            height: 0px;
            padding-right: 3%;
            transform: translateY(15%); }
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-from .arrow-view::before,
          .rounds-result-mobile .rounds-result-filter .filter-items .filter-to .arrow-view::before {
            content: "\e900";
            color: #fafafa; }
      .rounds-result-mobile .rounds-result-filter .filter-btn {
        padding: 1.05% 0;
        width: 100%;
        background-color: #197051;
        text-transform: uppercase;
        outline: none;
        border-radius: 4px;
        border: none;
        color: #f3f3f3;
        font-size: 14px; }
    .rounds-result-mobile .result-m {
      width: 100%;
      background: transparent;
      float: left; }
      .rounds-result-mobile .result-m .modal-content-wrapper .t-list-view .info-t-title > div.title {
        float: left;
        width: calc(100% / 3); }
      .rounds-result-mobile .result-m .modal-content-wrapper .t-list-view {
        background: transparent;
        height: auto;
        padding: 0 8px 8px;
        box-sizing: border-box;
        float: left; }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul
> li {
        display: table-cell;
        vertical-align: middle;
        width: calc(100% / 3); }
      .rounds-result-mobile .result-m .modal-content-wrapper .t-list-view .info-t-title {
        background: unset;
        opacity: unset;
        color: #a2a2a2;
        font-size: 12px;
        height: 38px; }
      .rounds-result-mobile .result-m .single-list-row .single-list {
        height: 30px;
        width: 100%;
        box-sizing: border-box;
        background: #ffffff0d; }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul
> li.width-li
.hotNumber {
        position: relative; }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul {
        height: 32px;
        display: flex;
        flex-wrap: wrap; }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul
> li.width-li {
        width: 80%; }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul
> li
.mobile0 {
        width: calc(100% / 3); }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul
> li
.mobile1 {
        width: calc(100% / 3); }
      .rounds-result-mobile .result-m .modal-content-wrapper
.t-list-view
.single-list-row
.single-list
.Collapsible
.Collapsible__trigger
> ul
> li
.mobile2 {
        width: calc(100% / 3); } }

/*...................................my version*/
.mobile-header {
  display: block; }

@media only screen and (max-width: 812px) {
  .desktop-header {
    display: none; }
  .mobile-header {
    display: block; }
  .created-tickets-wrapper .ticket-text {
    letter-spacing: 0.33px;
    background-color: #2e323e;
    text-align: center;
    color: #7B8191;
    overflow: visible;
    font-size: 14px;
    padding: 2px; }
  .l-column-wrapper {
    width: 100%;
    order: 2; }
  .c-column-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 0.209%;
    order: 3; }
  .r-column-wrapper {
    height: 100%;
    width: 100%;
    order: -1;
    position: relative;
    display: flex;
    flex-direction: column; }
  .r-top-box .lottery-tab-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 2;
    border-bottom: 2px solid;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 41px;
    display: flex;
    width: 100%;
    margin-top: 0; }
  .lottery-tab-wrapper::-webkit-scrollbar {
    display: none; }
  .r-top-box .lottery-tab-wrapper .lottery_tabs,
  .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    display: inline-flex;
    justify-content: center;
    border-bottom: 1px transparent; }
    .r-top-box .lottery-tab-wrapper .lottery_tabs.active:after,
    .r-top-box .lottery-tab-wrapper .r-tab.active:after {
      position: absolute;
      content: "";
      bottom: 0;
      margin: 0 auto;
      animation: mymove 0.4s forwards;
      border-bottom: 1px solid #a71f67; }
  @keyframes mymove {
    from {
      width: 0; }
    to {
      width: 100%; } }
  .r-top-box .r-tab {
    padding: 0.71% 3.5%; }
  .r-top-box .r-tab .r-text {
    will-change: scroll-position;
    white-space: nowrap;
    background-color: unset;
    font-size: 13px; }
    .r-top-box .r-tab .r-text span {
      margin-left: 0; }
    .r-top-box .r-tab .r-text:before {
      content: ""; }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0; }
  .r-top-box .r-tab .r-text:before {
    content: ""; }
  /*..............................Competition header*/
  .l-full-menu-wrapper-box
.Collapsible
.Collapsible__contentInner
.competition-title.active {
    background-color: #ffffff1c; } }

@media only screen and (max-width: 375px) {
  .r-top-box .lottery-tab-wrapper .lottery_tabs,
  .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    width: auto; }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0;
    font-size: 12px; }
  /*............................competitions header*/
  .favorites:before {
    padding-left: 0; }
  .favorites p {
    padding-left: 0; }
  /*.competition-title > p,
  .competition-title span,
  .fav-competition > p,
  .fav-competition span {
     font-size: 12px;
     //font-size: 2.8vw;
  }*/
  .sports-item:before {
    font-size: 5vw; }
  .sports-item > p {
    font-size: 14px; } }

@media only screen and (max-width: 812px) {
  /*filter section start*/
  .filter-view {
    display: flex;
    align-items: center;
    padding-left: 52px; }
    .filter-view .filter-row {
      order: 1;
      width: 18.21%;
      height: 26px;
      min-width: 95px; }
      .filter-view .filter-row .time-filter {
        width: 100%;
        background-color: #4d515b;
        border-radius: 4px;
        padding: 0; }
        .filter-view .filter-row .time-filter:before {
          color: #ffff;
          font-size: 1.3em;
          background-color: #4d515b;
          z-index: 99999;
          height: 100%;
          width: 0;
          left: 3%; }
        .filter-view .filter-row .time-filter select {
          border: none;
          outline: none;
          box-shadow: none;
          background-color: #4d515b;
          width: 64%;
          height: 100%;
          right: 0;
          position: absolute;
          color: #ffff;
          padding: 0px 18%; }
          .filter-view .filter-row .time-filter select option {
            background-color: #4d515b;
            color: #ffff; }
    .filter-view .second-row-filter {
      order: 2;
      width: 38%;
      min-width: 150px;
      padding: 0;
      background-color: #2E323E;
      border-radius: 4px;
      margin: 0 8px;
      height: 26px; }
      .filter-view .second-row-filter .search-box {
        height: 24px; }
        .filter-view .second-row-filter .search-box .block-search {
          border-radius: 4px; }
        .filter-view .second-row-filter .search-box .search-results-container .games-list-mini-view {
          padding: 0; }
          .filter-view .second-row-filter .search-box .search-results-container .games-list-mini-view .competition-title {
            margin: 1px 0 0; }
        .filter-view .second-row-filter .search-box:before {
          color: #ffffffa8;
          height: 100%;
          right: 2%;
          top: -8%; }
    .filter-view .r-tab {
      width: 15%;
      order: 3; }
      .filter-view .r-tab .r-text {
        height: 20px; } }

@media only screen and (max-width: 375px) {
  /*............................search-filter header*/
  .filter-view {
    padding-left: 15px; }
    .filter-view .r-tab {
      margin-right: 21px; }
  .filter-view .filter-row {
    width: 39%; }
  .filter-view .second-row-filter {
    width: 61.37%; }
  .search-box > form {
    border-radius: 4px; }
  .filter-view .second-row-filter .search-box:before {
    color: #fffc;
    font-size: 5.2vw;
    top: -2px; }
  .filter-view .filter-row .time-filter select {
    color: #cccccc; }
  .filter-view .filter-row .time-filter:before {
    font-size: 1.2em; } }

@media only screen and (max-width: 812px) {
  #creating-ticket .creating-ticket-wrapper {
    width: 100%;
    float: left;
    background: #2e323e;
    box-sizing: border-box;
    padding: 5px 5px 0 5px; }
    #creating-ticket .creating-ticket-wrapper .delete-wrapper {
      margin-right: 15px; }
    #creating-ticket .creating-ticket-wrapper .system-wrapper {
      color: #ffff; }
  #creating-ticket .Collapsible .Collapsible__trigger.is-open .ticket-item-wrapper {
    background: unset;
    box-shadow: unset; }
  .percent-bar-wrapper {
    width: 27%;
    float: right;
    margin-right: 12px; }
    .percent-bar-wrapper span {
      text-align: start; }
    .percent-bar-wrapper .rectangle {
      height: 13px;
      background-color: #383c4a;
      border-radius: 4px; }
      .percent-bar-wrapper .rectangle div {
        height: 13px;
        border-radius: 4px; } }

@media only screen and (max-width: 381px) {
  .ticket-item-wrapper .percent-bar-wrapper {
    display: none;
    float: none; }
  .tickets-container {
    height: 170px; }
    .tickets-container .ball-items-container .ball-item-wrapper .ball-item {
      height: 19px;
      width: 19px; } }

@media only screen and (max-width: 812px) {
  .ticket-m {
    width: 73.02%;
    height: 35.864%;
    max-height: 433px;
    min-height: 433px; }
    .ticket-m .modal-content-wrapper {
      width: 100%;
      overflow: hidden;
      height: 90%;
      background-color: #1e2638; }
      .ticket-m .modal-content-wrapper > p {
        padding: 2% 2%; }
      .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper {
        overflow: hidden;
        overflow-y: auto;
        height: 70%;
        width: 100%; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper > ul > li:first-child {
          opacity: 0.4;
          color: #fff;
          font-size: 12px;
          padding: 0; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper > ul:nth-child(2):before {
          border-left: 1px solid #ffffff;
          opacity: 0.1;
          height: 97%; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper > ul:nth-child(2):after {
          border-right: 1px solid #ffffff;
          opacity: 0.1;
          height: 97%; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items {
          margin: 8% 0;
          width: 100%;
          padding: 0.73% 0 0px 0px;
          position: relative; }
          .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items .hotNumber {
            width: 27.36%;
            height: 100%;
            position: absolute;
            bottom: 0;
            line-height: 0; }
          .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items .progress-container {
            display: block;
            width: 70%;
            border-radius: 2px;
            padding: 5% 0 0 0;
            margin-left: 35%; }
            .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items .progress-container .progress-bar-wrapper {
              height: 4px;
              background: #ffffff1a;
              border-radius: 2px; }
      .ticket-m .modal-content-wrapper > button {
        margin: 3% auto 0;
        width: 23.4%;
        border-radius: 4px;
        border: none;
        background: #ffffff26; }
        .ticket-m .modal-content-wrapper > button:hover {
          border: none;
          color: #C2C5CD; }
    .ticket-m .modal-header-w {
      background-color: #262d3c;
      border-bottom: unset; }
    .ticket-m .modal-header-w > h3 {
      font-size: 14px;
      background-color: #262d3c;
      border-bottom: unset;
      padding: 2.2% 2%;
      display: flex;
      align-items: center; }
    .ticket-m .closed-btn {
      top: 4px;
      right: 7px;
      color: #ffffff; } }

@media only screen and (max-width: 375px) {
  .ticket-m {
    width: 94.67%;
    height: 45%;
    max-height: 550px;
    max-height: 550px; }
    .ticket-m .modal-content-wrapper {
      width: 100%;
      overflow: hidden;
      height: 90%;
      background-color: #1e2638; }
      .ticket-m .modal-content-wrapper > p {
        color: #ffffff99;
        font-size: 11px;
        line-height: 16px;
        padding: 4%;
        margin: 0; }
      .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper {
        overflow: hidden;
        overflow-y: auto;
        height: 70%;
        width: 100%; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper > ul > li:first-child {
          opacity: 0.4;
          color: #fff;
          font-size: 12px;
          padding: 0; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper > ul:nth-child(2):before {
          border-left: 1px solid #ffffff;
          opacity: 0.1;
          height: 97%; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper > ul:nth-child(2):after {
          border-right: 1px solid #ffffff;
          opacity: 0.1;
          height: 97%; }
        .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items {
          margin: 8% 0;
          width: 100%;
          padding: 0.73% 0 0px 0px;
          position: relative; }
          .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items .hotNumber {
            width: 27.36%;
            height: 100%;
            position: absolute;
            bottom: 0;
            line-height: 0; }
          .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items .progress-container {
            display: block;
            width: 70%;
            border-radius: 2px;
            padding: 5% 0 0 0;
            margin-left: 35%; }
            .ticket-m .modal-content-wrapper .hotNumbers-items-wrapper .hotNumbers-items .progress-container .progress-bar-wrapper {
              height: 4px;
              background: #ffffff1a;
              border-radius: 2px; }
      .ticket-m .modal-content-wrapper > button {
        margin: 7% auto 4%;
        width: 23.4%;
        border-radius: 4px;
        border: none;
        background: #ffffff26; }
        .ticket-m .modal-content-wrapper > button:hover {
          border: none;
          color: #C2C5CD; }
    .ticket-m .modal-header-w {
      background-color: #262d3c;
      border-bottom: unset; }
    .ticket-m .modal-header-w > h3 {
      font-size: 14px;
      color: white; }
    .ticket-m .closed-btn {
      color: white; } }

.create-tickets {
  position: fixed;
  width: 100%;
  height: 55%;
  z-index: 950;
  bottom: 0; }
  .create-tickets .create-tickets-item {
    width: 100%;
    height: 100%;
    background: #2e323e; }
