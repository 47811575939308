.create-tickets {
  position: fixed;
 // position: absolute;
  width: 100%;
  height: 55%;
  //eft: 0;
 // top: 50%;
  z-index: 950;
 // padding-top: 64px;
  bottom: 0;
  .create-tickets-item {
    width: 100%;
    height: 100%;
    background: #2e323e; //#293142
    //position: relative;
  }
}