@media only screen and (max-width: 812px) {

  .l-full-menu-wrapper {
    //display: flex;
  }
  .favorite-view.left-nav-view {
    //idth: 33.33%;

    .favorites ul li {
      margin-left: 1.5px
    }

    //margin-right: 3px
  }
  .left-nav-view {
    //width: 66.66%;
  }
  .left-nav-view > ul, .favorites > ul {
    display: flex;
  }
  .favorites p {
    padding-left: 5px;
  }
  .favorites:before {
    padding-left: 3px;
  }
  .sports-item:before {
    box-shadow: none;
  }


  .l-full-menu-wrapper-box {
    margin-top: 0;

    .competition-title {
      background-color: #2e323e;
      height: 33px;
      padding-top: 5px
    }

    ul li {
      margin-left: 1.5px;
    }

    .Collapsible .Collapsible__contentInner .competition-title {
      color: #fff;
      margin: 0;
      //opacity: 0.1;
      &.active {
        background-color: #4d515b;
      }
    }

    .sports-item {
      background-color: #2e323e;
      height: 35px;

      &.before {
        color: #ffff
      }
    }

    .left-nav-view > ul > li .float-width, .favorites > ul > li .float-width {
      // margin-left: 4px
    }

    .games-list-mini-view {
      padding: 0;
      border: none;
    }

    .arrow-view {
      padding-right: 25px;
    }

  }

  #web-game-container {
    display: none;
  }
  #mobile-game-container {
    display: block;
  }
  .c-column-wrapper {
    flex-direction: column-reverse;
    display: flex;

    .game-lobby-wrapper {
      float: left;
      flex-direction: column;

      .game-container {

        div {

          .info {
            span {

            }

            &:before {

            }
          }

          .round-start-time {

            &:before {

            }

            span {

            }
          }
        }

        .border-game-wrapper {

          .game-wrapper {

            .placed-bets-container {

            }

            .ball-animation-container {

              .ball-animation-wrapper {

              }
            }
          }
        }


      }

      .balls-container {
        .ball-item-wrapper {
          width: 28px;
          //width: 8.48%;
          //width: 5.48%;
          // height: 27.9px;
        }
      }
    }

    .lobby-wrapper {
      height: 220px
    }

    #lobby-web {
      display: none;
    }

    #lobby-mobile {
      display: block;
    }

    #mobile-lobby-wrapper {
      display: block;
      height: 180px;

      .t-list-view {
        font-size: 14px;
      }

      .t-list-view div {
        height: 35px;
        // width: 49.9%;
        // float: right;

        &:nth-child(1) {
          //border-left: 1px solid rgba(0, 0, 0, 0.25);
        }

        select {
          -webkit-appearance: none;
          box-sizing: border-box;
          color: #fff;
          border: none;
          background: #343844;
          padding: 0 30px 0 11px;
          width: 100%;
          height: 35px;
          position: relative;
          cursor: pointer;
          border: none;
          outline: none;
          /* option {
             border: none;
             outline: none;
           }*/


          background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
          linear-gradient(135deg, #fff 50%, transparent 50%),
          linear-gradient(to right, #343844, #343844);
          background-position: calc(100% - 40px) calc(1em + 2px),
          calc(100% - 35px) calc(1em + 2px),
          100% 0;
          background-size: 5px 5px,
          5px 5px,
          2.5em 2.5em;
          background-repeat: no-repeat;
        }

        ul {
          width: 100%;
          display: block;
          height: 35px;

          li {
            display: block;
            width: 100%;

            div {
              width: 100%;

              .favorite-view {
                padding-right: 27px;
                width: auto;
                border-right: 1px solid rgba(0, 0, 0, 0.25);
                //background: #343844;
                border-left: none;
                //display:none;
              }

              p {
                padding-left: 0;
              }
            }
          }
        }

        .info-c-type {
          font-size: 14px;
          background-color: #2e323e;
          margin: 0;
          border-bottom: none;
          .favorite-view {
            background-color: #2e323e;
          }
          li .type-item .flag-view {

          }
        }
      }

      .t-list-view {
        div ul li div .flag-view {
          margin-left: 12px
        }

        .mobile-rounds {
          will-change: scroll-position;
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          height: 45px;
          margin-top: 7px;
          display: flex;

          .rounds-elements {
            cursor: pointer;
            float: left;
            width: auto;
            padding: 2px 30px 2px;
            display: block;
            border-radius: 4px;
            border: solid 1px #ffffff;
            height: 22px;
            text-align: center;
            margin-left: 5px;

            &:first-child {
              margin-left: 10px
            }
          }

        }

        .active {
          background-color: #fff;
          color: #343844;
        }


        .rounds-item {
          will-change: scroll-position;
          white-space: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          height: 58px;
          display: contents;

          .rounds-item-elements {
            width: auto;

            &:first-child {
              margin-left: 10px;
            }

            div {
              width: 82px;
            }

          }

          .all-rounds-list {
            will-change: scroll-position;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            height: 58px;
            display: flex;
            font-size: 13px;
            text-align: center;

            .rounds-item-elements {
              text-align: left;

              span {
                width: 82px;
                display: block;

                &:nth-child(2) {
                  color: #C2C5CD;
                }

                &:first-child {
                  padding: 0 0 4px;
                }
              }

              &:first-child {
                text-align: left;
              }
            }
          }

          .show-round {
            visibility: visible;
          }
        }


        .all-rounds-list::-webkit-scrollbar {
          display: none;
        }

        .mobile-rounds::-webkit-scrollbar {
          display: none;
        }
      }

      .favorite-view {
        width: auto;
      }

      .favorite-view {
        width: auto;
      }

    }


    #web-lobby-wrapper {
      display: none;
    }


  }


  .mobile-show-count {
    display: block;
    right: 30px;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    line-height: inherit;
    text-align: center;
    transition: color 240ms;
    color: #ccc;
    font-size: 9pt;
  }

}

@media only screen and (max-width: 375px) {
  .l-full-menu-wrapper {
    display: block;
  }
  .left-nav-view, .favorites {
    width: 100%;
  }
  .l-full-menu-wrapper-box {
    height: auto;
  }
  .favorite-view.left-nav-view {
    width: 100%;
  }
  .left-nav-view > ul, .favorites > ul {
    display: block;
  }

}


@media only screen and (max-width: 375px) {
  .r-top-box .lottery-tab-wrapper .lottery_tabs, .r-top-box .lottery-tab-wrapper .r-tab {
    flex-grow: 1;
    position: relative;
    width: auto;
  }
  .r-top-box .r-tab .r-text > span {
    margin-left: 0;
    font-size: 2.6vw;
  }


  /*............................competitions header*/
  .favorites:before {
    padding-left: 0;
  }
  .favorites p {
    padding-left: 0;
  }
  .competition-title > p, .competition-title span, .fav-competition > p, .fav-competition span {
    font-size: 12px;
    //font-size: 2.8vw;
  }
  .sports-item:before {

    font-size: 5vw;
  }
  .sports-item > p {
    font-size: 2.8vw;
  }
  .l-full-menu-wrapper-box ul li {
    margin-left: 1.5px;
    margin: 0;
  }
  .l-full-menu-wrapper-box .left-nav-view > ul > li .float-width, .l-full-menu-wrapper-box .favorites > ul > li .float-width {
    margin-left: 0;
  }
  .l-full-menu-wrapper-box .arrow-view {
    padding-right: 18px;
  }
  .info-c-type > li .type-item .favorite-view.active:before {
    display: none;
  }

  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div ul li div .flag-view {
    margin-left: 10px;
  }
  .t-list-view {
    width: 100%;
    font-size: 3.2vw;
    display: flex;
    flex-direction: column;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div {
    width: 100%;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div:nth-child(2) {
    background: #ffffff1c;
    order: 0;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div {
    width: 100%;
    order: 1;
    height: 26px;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div select {
    font-size: 12px;
    height: 26px;
    padding-left: 15px;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view div .info-c-type {
    padding: 0;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .mobile-rounds .rounds-elements:first-child {
    margin-left: 15px;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .rounds-item-elements:first-child {
    margin-left: 15px;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list {
    padding-left: 15px;
  }
  .c-column-wrapper #mobile-lobby-wrapper .t-list-view .rounds-item .all-rounds-list .rounds-item-elements {
    background: unset;
  }

}
  