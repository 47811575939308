@import 'colors';

.r-column-wrapper{
    width: 15.364%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    float:left;
}
/*tabs start */
.r-top-box{
    .lottery-tab-wrapper{
        background: #2E323E;
        box-sizing:border-box;
        margin-top: 2px;
        .round-text{
            width:auto;
            color: #7B8191;
            letter-spacing: 0.33px;
            font-size: 12px;
            text-align: center;
            line-height: 2.5;
            text-transform: uppercase;
            span {
                cursor: pointer;
            }
        }
      
        .lottery_tabs{
            border:none;
            box-sizing:border-box;
            width:60%;
            .text{
                width:auto;
                color: #c5c5c6;
                letter-spacing: 0.33px;
                font-size: 12px;
            }
            .count{
                text-align:left;
                width:auto;
                padding-left: 5px;
                font-size:11px;
                >span{
                    background:transparent;
                }
            }
            &.active{
                color:#fff;
                background:unset;
            }
        }
    }
    .r-tab{
        display: inline-block;
        height:100%;
        padding: 0 3.5%;
        box-sizing: border-box;
         border:none;
            box-sizing:border-box;
            width:40%;
        cursor: pointer;
        .r-text{
            text-overflow: ellipsis;
            text-transform:uppercase;
            letter-spacing: 0.33px;
            border-radius: 2px;
            background-color: #7B8191;
            padding: 2%;
            font-size: 12px;
            text-align:center;
            color: #fff;
            box-sizing:border-box;
            position:relative;
            // &:before {
            //     font: 1.2vw/16px icomoon;
            //     content: "\e90d";
            //     color: #fff;
            //      position: absolute;
            //     left: 5%;
            //     display: inline-block;
            // }
            // >span{
            //     margin-left: 25%

            // }
        }
    }
    .raunds-history-button{
        color: #fff;
        width: 100%;
        background-color: #7b8191;
    }
}
/*tabs end */

/*ticket start */
.r-full-menu-wrapper-box {
    height: calc(100% - 40px);
    overflow:hidden;
    margin-top:2px;
    .fav-numbers-info-box{
        width:100%;
        height: 26.73vh;
        background: $MidnightExpress;
        border-radius: 0 0 2px 2px;
        display:flex;
        >p{
            color: #7B8191;
            font-size: 11px;
            letter-spacing: 0.33px;
            margin:auto;
            text-align:center;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }
    .tickets-container{
        width:100%;
        height: 100%;
        border-radius: 0 0 2px 2px;
        background: #212532;
        box-sizing: border-box;
        overflow-x: auto;
        >div{
            overflow: hidden;
        }
        .ticket-text {
            letter-spacing: 0.33px;
            background-color: #2e323e;
            text-align: center;
            color:#f2f2f2;
            overflow: visible;
            font-size: 14px;
            padding: 2px;
        }
        .winning-tickets{
            .ticket-text {
                background-color: #349572;
            }
        }
        .waiting-tickets{
            .ticket-text {
                background-color: #c4953e;
            }
        }
    }
}


.creating-ticket-wrapper, .created-tickets-wrapper{
    width:100%;
    float:left;
    background: #212532;
    box-sizing: border-box;
    padding:5px 5px 0 5px;
}
.created-tickets-wrapper{
    .ticket-item-wrapper{
        cursor:auto;
        .ticket-item{
            padding: 0 0 3% 3.5%;
        }
    }
}

.ticket-item-wrapper{
    height: auto;
    width: 100%;
    float: left;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    background: linear-gradient(270deg, #333743 100%, #333743 0%);
    margin-bottom:5px;
    box-sizing: border-box;
    cursor:pointer;
    position:relative;
    .ticket-item, .t-balls{
        width:50%;
        box-sizing:border-box;
        height:100%;
        float:left;
    }
    .info-create-tickets {
        width: 100%;
        height: 20px;
        display: block;
        background-color: #0cae36;
        color: #ffff;
        font-size: 13px;
        text-align: start;
        text-transform: capitalize;
    }
}
.ticket-item{
    padding: 2.5% 0 3% 3.5%;
    >div{
        >span{
            display:block;
            }
        }
}
.ticket{
    color: #7B8191;
    font-size: 12px;
}
.country{
    //color: #C2C5CD;
    color:#a6abba;
    font-size: 12px;
}
.win, .loose{
    width: 99px;
    color: #29976F;
    font-size: 12px;
    letter-spacing: 0.36px;
    line-height: 14px;
    margin-top:5%;
}
.loose{
    color: #B73C3C;
}
.ticket-number-wrapper{
    >span{
        vertical-align:middle;
    }
    .delete-created-ticket{
        cursor:pointer;
        &:before{
            font: 18px/0 icomoon;
            content: "\e902";
            color: #b5b8c0;
            margin-right: 3px;
        }
    }
}

.delete-wrapper{
    position: relative;
    padding: 5px 0;
    display: table;
    &:before{
        font: 22px/30px icomoon;
        content: "\e903";
        color: #b73c3c;
        margin-left: -5px;
    }
    .delete{
        display: table-cell;
        vertical-align: middle;
        padding-left: 3px;
        color: #b73c3c;
        font-size:11px;
    }
}

.bet{
    color: #AA8136;
    font-size: 12px;
    letter-spacing: 0.36px;
    line-height: 14px;
}
.t-balls{
    padding: 2% 0;
    &:before{
        content:"";
        position:absolute;
        height: 92%;
        width:1px;
        background: #212532;
    }
    .ball-items-container{
        box-sizing: border-box;
        padding-left: 6%;
        width:100%;
        display:flex;
        flex-wrap:wrap;
        .ball-item-wrapper{
            // width:20px;
            // height:20px;
            width: 15%;
            height: 28%;
            margin: 1% 2%;
            .ball-item{
                font-size:11px;
                &:hover{
                    background: rgba(255, 255, 255, 0.9) !important;
                    color: rgb(0, 0, 0) !important;
                    border:none !important;
                }
            }
        }
    }
}
.created-tickets-wrapper{
    .t-balls{
        .ball-item{
            pointer-events:none !important;
        }
    }
}
/*ticket end */

/*ticket-menu start */
.ticket-full-menu{
    width: 100%;
    padding: 0 2% 2%;
    box-sizing: border-box;
    >button{
        padding: 2.6% 0;
        border:none;
        outline:none;
    }
}

.Collapsible{
    .Collapsible__trigger.is-open{
        .ticket-item-wrapper{
          background: linear-gradient(180deg, #333743, rgba(51,55,67,0));
          box-shadow:unset;
        }
        .data-container{
            >p{
                color:#fff;
            }
        }
    }
}
.percent-bar-wrapper{
    width:100%;
    padding-bottom: 7%;
    >span{
        color: #C2C5CD;
        font-size: 11px;
        display:block;
        margin-bottom:2.7%;
    }
    .span{
        padding: 0 2px 9px 0;
        color: #C2C5CD;
        font-size: 13px;
        font-weight: bold;
    }
    .ticket{
        padding: 2px 9px;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        border-radius: 2px;
        background-color: #0cae36;
        margin-left:5px;
    }
    .span, .ticket{
       display:inline-block;
    }

.rectangle {
    height: 10px;
    width:100%;
    border-radius: 2px;
    background-color: rgba(0,0,0,0.5);

  }
  .percent-bar{
    height: 10px;
    opacity: 0.8;
    border-radius: 2px;
    width:0;
    }

}

.green-bar{
    background: linear-gradient(-145deg, #249E71, #1A7051);
}
.yellow-bar{
    background: linear-gradient(-145deg, #F0B74D , #AA8136);
}

.system-wrapper{
    border-top: 1px solid #0E141A;
    padding:7% 0;
    display:flex;
    color: #7B8191;
    font-size: 11px;
    >div{
        width:50%;
        margin:auto
    }
    .system-item{
       margin:auto;
       display:inline-block;
       width:50%;
       text-align: right;
       >div{
        display:inline-flex;
        //border-radius: 5px;
        width: 49%;
        background-color: #2E323E;
        position: relative;
        padding: 1.3% 0;
        >select{
            width:100%;
            outline: none;
            border: none;
            color: #7b8191;
            cursor: pointer;
            display: inline-flex;
            background-color: #2E323E;
            //border-radius: 3px;
            padding: 0 17%;
            -webkit-appearance: none;
            >option{
                border:2px solid black;
                outline:none;
            }
           }
            }
            .arrow{
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                color: #7b8191;
                pointer-events: none;
                &:after {
                    font: 21px/26px icomoon;
                    content: "\e90f";
                    color: #7B8191;
                    transform: rotateX(180deg);
                    display: inline-block;
            }
       }
    }
}

.possible-winnings{
    padding: 6.7% 0;
    box-sizing: border-box;
    //float: left;
    width: 100%;
    border-top: 1px solid #0E141A;
    .amount-container{
        box-sizing:border-box;
        width:100%;
        height:30px;
        margin-bottom: 4%;
        >p{
            height: 100%;
            width: 14%;
            background-color: #AA8136;
            margin:0;
            float:left;
            color:#fff;
            text-align:center;
            line-height:30px;
            margin-right:2%;
        }
        >div{
            padding: 0 2% 0 4%;
            color: #FFF;
            font-size: 11px;
            display: table;
            box-sizing: border-box;
            height: 100%;
            width: 84%;
            background-color: #2E323E;
            border: 1px solid #505767;
            >input{
                outline: none;
                height: 100%;
                width: 100%;
                border: none;
                background-color: #2E323E;
                color: #FFF;
                display: table-cell;
                &::placeholder {
                    color: #7B8191;
                  }
            }
            >span{
                display: table-cell;
                vertical-align: middle;
                color: #7B8191;
            }
        }
    }
}
.maxBet{
    cursor:pointer;
}
.bet{
    .data-container {
        height: 14px;
        color: #7B8191;
            &:last-child{
                >p{
                    color:#fff;
                }
             color:#fff;
        }
    }
}
.bet-err-text{
    margin: 0 0 5px;
    color: #c01d1d;
}
.data-container {
    position: relative;
    padding: 1.5px 0;
    color: #fff;
    font-size: 11px;
    &:first-child{
        margin-bottom:2%;
        cursor:pointer;
            .arrow-view{
                font: 20px/20px icomoon;
            }
    }
   .min-max-bet{
    font-weight: 700;
    color: #ffffffad;
   }
    >p{
        float: left;
        display: inline-block;
        margin: 0;
        color: #7B8191;
    }
    .arrow-view{
       &:before{
        color: #7B8191;
       }
    }
    >div{
        display: inline-block;
        right: 0;
        position: absolute;
        span:last-child {
            margin-left: 5px;
        }
    }
}

/*ticket-menu end */