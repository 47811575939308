@import "colors";

.c-column-wrapper {
   width: 68.542%;
   height: 100%;
   float: left;
   margin: 0 0.209%;
}
.game-lobby-wrapper {
   width: 100%;
   //height:296px;
   height: 30.547%;
   display: flex;
   > div {
      height: 100%;
   }
}
/*Keno start*/
#mobile-game-container {
   display: none;
}
.game-container {
   width: 49.089%;
   margin-right: 0.305%;
   box-sizing: border-box;
   border-radius: 2px;
   background: linear-gradient(45deg, $MidnightExpress 100%, $Vulcan  0%);
   //background: $VulcanShade;
   padding: 0.8% 0.8% 2%;
   .info,
   .round-start-time {
      color: $StormGrey;
      font-size: 14px;
      display: table;
      padding: 0.2% 0;
      > span {
         display: table-cell;
         vertical-align: middle;
         padding-left: 5px;
      }
   }
   .info {
      &:before {
         font-family: "icomoon";
         content: "\e908";
         font-size: 1.1vw;
      }
   }
   .round-start-time {
      > span {
         &:last-child {
            font-weight: 500;
            padding-left: 10%;
         }
      }
      &:before {
         font-family: "icomoon";
         content: "\e912";
         font-size: 1.1vw;
      }
   }
}
.startAnimation {
   animation: ball 2.5s 1 forwards;
}
.game-wrapper {
   width: 100%;
   height: 50.16%;
   display: flex;
   padding: 3% 0 0;
   .placed-bets-container {
      width: 61.294%;
      height: 60%;
      background: #00000033;
      margin: auto;
      border-radius: 19px;
      box-sizing: border-box;
      position: relative;
      .ball-items-container {
         width: 100%;
         height: 100%;
         margin: 2px 0px 0px 4px;
         padding: 0;
         .ball-item-wrapper {
            width: 8.95%;
            height: 43.59%;
            margin: 0 1% 0 0;
            .ball-item {
               pointer-events: none;
            }
         }
      }
      .remaining-time {
         width: 100%;
         height: 100%;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         position: relative;
         padding-left: 1%;
         box-sizing: border-box;
         .spinner-circular {
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            position: absolute;
            &:last-child {
               display: table;
               text-align: center;
               > span {
                  display: table-cell;
                  vertical-align: middle;
                  color: $LinkWater;
               }
            }
         }
         .one {
            &:before {
               content: "";
               display: block;
               box-sizing: border-box;
               width: 100%;
               height: 100%;
               border-radius: 50%;
               border: 5px solid #7b81914d;
            }
         }
         .two {
            &:before {
               content: "";
               display: block;
               box-sizing: border-box;
               width: 100%;
               height: 100%;
               border-radius: 50%;
               border-top: 5px solid $LinkWater;
               border-right: 5px solid transparent;
               animation: spinner 1200ms linear infinite;
            }
         }
         @keyframes spinner {
            100% {
               transform: rotate(360deg);
            }
         }
      }
   }
   .ball-animation-container {
      margin: auto;
      height: 100%;
      width: 39.516%;
      .ball-animation-wrapper {
         height: 100%;
         width: 53.07%;
         background: radial-gradient(
            circle,
          $BlackRussian 0%,
          $BlackRussian 97%,
            rgba(14, 20, 26, 0) 100%
         );
         margin: auto;
         border-radius: 50%;
         padding: 4%;
         box-sizing: border-box;
         //position:relative;
         .ball-animation {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: url("../static/images/bigBall.png");
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            //position:absolute;
            animation: ball 2.5s 1 forwards;
            @keyframes ball {
               0% {
                  margin-top: -130px;
                  opacity: 1;
               }
               35% {
                  margin-top: 0;
                  opacity: 1;
               }
               70% {
                  margin-top: 0;
                  opacity: 1;
               }
               100% {
                  // margin-top: 130px;
                  opacity: 0;
               }
            }
            .drawn-number {
               display: flex;
               width: 100%;
               height: 100%;
               > span {
                  $black: #000;
                  font-size: 17px;
                  font-weight: 700;
                  transform: rotate(-10deg);
                  margin: auto;
               }
            }
         }

         .round-start-time {
            width: 100%;
            height: 100%;
            text-align: center;
            &:before {
               content: "";
            }
            > span {
               color: $white;
               font-size: 18px;
               font-weight: 500;
               padding: 0;
            }
         }
      }
   }
}
.placed-bets-info-wrapper {
   width: 100%;
   height: 20%;
   span {
      color: #7b8191;
      font-size: 12px;
      font-weight: 500;
      display: block;
   }
   .placed-bets-info {
      width: 60.484%;
      float: left;
      height: 100%;
      > h1 {
         color: $LinkWater;
         font-size: 14px;
         font-weight: 500;
         letter-spacing: 2.1px;
         text-transform: uppercase;
         margin: 0;
      }
      .round-id-wrapper {
         width: 100%;
         display: flex;
         .flag {
            margin: 1.5% 3% 0 0;
            position: relative;
         }
         .flag-icon-c {
            width: 28px;
            height: 22px;
         }
         .round-id {
            margin-right: 5%;
         }
      }
   }
   .placed-bets-count {
      width: 39.516%;
      height: 100%;
      float: left;
      display: flex;
      > span {
         margin: auto;
         padding: 2px 20px;
         white-space: nowrap;
         border-radius: 13px;
         background-color: $MidnightExpress;
         border: 1px solid $VulcanShade;
      }
   }
}
.animation {
   transition-duration: 1s;
}

/*Keno end*/
