@import 'colors';

/*Balls start*/

.balls-container{
    width: 50.61%;
    height:100%;
    padding: 5px 0.76% 5px 0;
    box-sizing:border-box;
    background:$MidnightExpress;
    border-radius:2px;
}

.ball-items-container{
    width: 78.354%;
    height: 100%;
    padding: 0 1.144%;
    position:relative;
    display: flex;
    flex-wrap: wrap;
    float: left;
    &.no-bet {
        opacity: 0.5;
        pointer-events: none;
    }
}
.ball-items-wrapper{
    position: absolute;
    height: 100%;
    display: flex-wrap;
    display: flex;
    flex-wrap: wrap;
}
.ball-item-wrapper{
    // background: radial-gradient(circle,   $white 0%, #fffc 50%,  $white 50%);
    text-align: center;
    width: 6.615%;
    height: 11.89%;
    border-radius: 50%;
    margin: 0 1.078% 0.35% 0;
    padding: 0.195%;
    opacity: 0.8;
    background-color: rgba(0,0,0,0.2);
    box-shadow: inset 0 0 10px 0 rgba(0,0,0,0.5);
    box-sizing: border-box;
        &:last-item{
        margin:0
    }
}
.ball-item{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    display:table;
    font-weight:500;
    box-shadow: 0 0 0px 1.5px #fffc;
    cursor:pointer;
    >span{
        display: table-cell;
        vertical-align: middle;
    }
    &:hover{
        background: #49ac89 !important; 
        color:#fff !important;
        border:1.5px solid;
    }
    &.active{
        background:#1A7051 !important;
        color: #fff !important;
        box-shadow: 0 0 0 1.5px #1A7051 !important;
        border-color: rgb(255, 255, 255) !important;
        border:1.5px solid;
    }
    &.matched{
        background:#AA8136; 
        color:#fff; 
        border: #AA8136; 
        box-shadow : 0 0 10px 0 rgba(255,255,255,0.5);
    }
    &.disabled{
        pointer-events:none,
    }
}

.ball-item-dropped {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    display: table;
    font-weight: 500;
    box-shadow: 0 0 0px 1.5px #fffc;
    cursor: pointer;
    > span {
        display: table-cell;
        vertical-align: middle;
    }
}


/*Balls end*/

/*HotNumbers start*/
.hotNumbers-container{
    float:left;
    display:flex;
    flex-direction:column;
    width: 19%;
    height: 100%;
    border-radius: 5px;
    background-color: $VulcanShade;
    padding: 0.61%;
    box-sizing:border-box;
    .loading-wrapper{
        height:63%;
    }
    >button{
        background: $MediumCarmine;
        margin:0;
        height: 28px;
        &:hover{
            background:#df4e4e;
        }
    }
    .hotNumbers-wrapper{
        background:$MidnightExpress;
        padding: 5.15%;
        box-sizing:border-box;
        border-radius: 5px;
        height:auto;
        >p{
            color: $LinkWater;
            // height: 35px;
            // width: 108px;
            margin: 0 0 10% 0;
            font-size: 14px;
            text-align: center;
            line-height: 13px;
        }
        
        .h-show-more {
            width: 100%;
            padding: 9% 0;
            position: relative;
            text-align: center;
            cursor:pointer;
            &:before{
                //content: url('/static/images/svg/dots.svg') ;
                content:"";
                background-color: #C2C5CD;
                height: 3px;
                width: 3px;
                position: absolute;
                border-radius:50%;
                margin-left:6px;
            }
            &:after{
                content:"";
                background-color: #C2C5CD;
                height: 3px;
                width: 3px;
                position: absolute;
                border-radius:50%;
            }
        }
    }
}
.hotNumbers-items-wrapper{
   width:100%;
   height:auto;
}
.hotNumbers-items{
   display:flex;
   justify-content:space-between;
   margin-top: 2%;
}
.hotNumber{
    box-sizing: border-box;
    //padding:3.5% 4.54% 3.5% 4.4%;
    //width:5%;
    width: 21px;
    height:4.5%;
    opacity: 0.4;
    border: 1px solid #FFF;
    color:#fff;
    display:table;
    border-radius:50%;
    line-height: 18px;
    >span{
        //font-size: 11px;
        font-size: 0.6vw;
        font-weight: 500;
        text-align: center;
        display:table-cell;
        vertical-align:middle;
    }
}
.progress-container{
    height:auto;
    width: 70%;
    border-radius: 2px;   
    padding: 5% 0 0 0;
    .progress-bar-wrapper{
        height: 2px;
        background: rgba(0,0,0,0.4);
        margin-bottom:5%;
        margin: auto;
        .progress-bar{
           // width:40%;
            height:100%;
            opacity: 0.8;
            border-radius: 2px;
            background: linear-gradient(-145deg, #249E71, #1A7051);
        }
    }
    >span{
        opacity: 0.8;
        color: $StormGrey;
        font-size: 10px;
        float:left;
        &:last-child{
            float:right;
        }
    }
}
.random-select-container{
    height: 23%;
    width: 100%;
    position: relative;
    border-radius: 2px;
    color:$white;
    > button{
        background: #b73c3c;
        margin: 0;
    }
   
}
    .random-select-wrapper{
        height: 100%;
        width: 100%;
        position:relative;
        background-color: #1A7051;
        color:$white;
        margin: 4% 0;
        .random-select{
        height:50%;
        width:75%;
        margin:auto;
        display: flex;
        //padding: 4% 0 0 0;
        padding:0;
        box-sizing: border-box;
        .decrement{
            cursor:pointer;
            &:before{
                font-family: 'icomoon';
                content: "\e90b";
                font-size: 23px;
            }
        }
        .increment{
            cursor:pointer;
            &:before{
                font-family: 'icomoon';
                content: "\e90c";
                font-size: 23px;
        }
        }
        .selected-number{
            width:40%;
            text-align:center;
            >span{
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.42px;
                line-height: 32px;
            }
        }
    }
        >button{
            text-overflow: ellipsis;
            white-space: nowrap;
            letter-spacing: 0.36px;
            font-size: 12px;
            text-align: center;
            margin: 0;
            position: absolute;
            background: linear-gradient(0, #42a682, #1a7051);
            bottom: 0;
            padding: 0;
            height:50%;
            overflow: hidden;
            &:hover{
                background: linear-gradient(0, #75f3c7, #1a7051);
            }
        }
    }


/*HotNumbers end*/