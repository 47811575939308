@media only screen and (max-width: 812px){
    .r-full-menu-wrapper-box {
        height: auto;
        overflow: hidden;
      
        .fav-numbers-info-box{
            display:none;
        }
    }
    .finished-t-mobile{
        border-top: 6px solid #212532;
    }
    .creating-t-mobile{
       
    }
    .waiting-t-mobile{
       
    }
    .tickets-container {
        border-radius: 0;
        //margin: 2% 0 0% 0;
        //border-bottom: 4px solid #14171e;
    }
    .tickets-container .created-tickets-wrapper {
        overflow-y: auto;
    }
    .tickets-container .waiting-tickets {
       max-height:222px;
       .delete-created-ticket {
        position: absolute;
        right: 0;
        margin-top:3px;
        &:before {
            content: "\e901";
            color: #ffffff;
            font-size: 22px;
            }
        }
    }
    .tickets-container .finished-tickets {
        max-height:136px;
     }
     .tickets-container .ticket-text {
        display:none;
        }
    .created-tickets-wrapper {
        padding: 0;

        .ticket-item-wrapper {
            border-radius: 0;
            box-shadow: unset;
            margin-bottom: 2px;
            padding: 15px 0 10px;

            &:last-child{
                margin-bottom:0;
            }
        }
       
        .ticket-item-wrapper .ticket-item {
            display: flex;
            position: relative;
            margin-bottom: 5%;
            width: 100%;
            div {
                span {
                    width: 100%;
                }
            }
        }
        .ticket-item-wrapper .ticket-item, .ticket-item-wrapper .t-balls {
            height: 57px;
            padding: 0 0 0 52px;
            flex-wrap:wrap;
            width:100%;
        }
        .ticket-item-wrapper .ticket-item > div{
            display: flex;
            align-items: center;
            &:first-child{
                flex-grow: 1;
                width: 79%;
            }
            &:last-child{
                flex-grow:1;
            }
        }
     
        .ticket-number-wrapper{
            margin-right:5%;
         .ticket{
             font-size:11px;
             position: relative;
             display:inline-block;
             padding-right:4px;
            &:before{
                content: "";
                border-right: 1px solid #ffffff33;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
            }
         }
         .country{
            font-size: 11px;
            position: relative;
            display:inline-block;
           &:before{
               content: "";
               border-right: 1px solid #ffffff33;
               position: absolute;
               right: 0;
               top: 0;
               height: 100%;
           }
        }
           
           
        }
        .ticket-item > div > span {
            margin: 0 5% 0 0;
            display:inline-block;
        }
        .t-balls:before {
            display:none;
        }
        .t-balls .ball-items-container {
            padding: 0;
        }
        .t-balls .ball-items-container .ball-item-wrapper {
            width: 3.22%;
           // height: auto;
            padding: 3.22% 0 0 0;
            margin: 0 4.5% 0 0%;
            position: relative;
        }
       
        .t-balls .ball-items-container .ball-item-wrapper .ball-item {
            font-size: 11px;
           //width: 100%;
           // height: 100%;
            width: 23px;
            height: 23px;
            bottom: 0;
            position: absolute;
        }
        .ball-item > span {
            color: #1e2638;
            font-size:11px;
        }
       
    }
}
@media only screen and (max-width: 620px){
    .created-tickets-wrapper .ball-item > span {
        font-size: 9px; 
    }
    
    .created-tickets-wrapper  .ticket-item-wrapper{
        margin-right:5%;
       .ticket, .country, .bet, .loose{
            font-size: 11px
            }
    }
  
}
@media only screen and (max-width: 375px){
    .r-full-menu-wrapper-box .created-tickets-wrapper .ticket-item-wrapper .ticket-item, .r-full-menu-wrapper-box .created-tickets-wrapper .ticket-item-wrapper .t-balls {
        height: 53px;
       // height: 25px;
        padding: 0 15px;
        flex-wrap: nowrap;
    }
    .r-full-menu-wrapper-box .created-tickets-wrapper .ticket-item-wrapper .t-balls {
        flex-wrap: wrap;
    }
    .ball-item-wrapper {
      //  margin: 0 16px 0 0px;
    }
    
}