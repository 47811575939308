@import 'colors';
.loading-wrapper{
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    //position:absolute;
    overflow-y: hidden;
}
.ball-items-container{
    .loading-wrapper{
        position:absolute !important;
    }
}
.progress-circular{
    width: 6%;
    height: 11.9%;
    margin: auto;
    &:before{
        content: '';
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border-top: 4px solid #a61f67;
        border-right: 4px solid transparent;
        animation: loadingSpinner 1200ms linear infinite;
    }
}

@keyframes loadingSpinner {
    100% {
        transform: rotate(360deg);
    }
} 