@media only screen and (max-width: 812px) {
  #creating-ticket {
    .creating-ticket-wrapper {
      width: 100%;
      float: left;
      background: #2e323e;
      box-sizing: border-box;
      padding: 5px 5px 0 5px;

      .delete-wrapper {
        margin-right: 15px;
      }

      .system-wrapper {
        color: #ffff;

      }
    }

    .Collapsible .Collapsible__trigger.is-open .ticket-item-wrapper {
      background: unset;
      box-shadow: unset;
    }
  }
  .percent-bar-wrapper {
    width: 27%;
    float: right;
    margin-right: 12px;

    span {
      text-align: start;
    }

    .rectangle {
      height: 13px;
      background-color: #383c4a;
      border-radius: 4px;

      div {
        height: 13px;
        border-radius: 4px;
      }
    }
  }
}

@media only screen and (max-width: 620px) {

}

@media only screen and (max-width: 381px) {
  .ticket-item-wrapper {
    .percent-bar-wrapper {
      display: none;
      float: none;
    }
  }
  .tickets-container {
    height: 170px;
    .ball-items-container {
      .ball-item-wrapper {
        .ball-item {
          height: 19px;
          width: 19px;
        }
      }
    }
  }



}